import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { CompanyEntity } from '@deecision/dna-interfaces';
import KeyValueWithCard from '@/components/keyvalue/keyvaluewithcard';
import { CompanyGetter } from '@/main/providers/getter';
import BaseWidget from '@/main/containers/widgets/base-widget';
import { WidgetProps } from '@/main/containers/widgets/types';

function CompanyWidgetsFinancial(props: WidgetProps<CompanyEntity>): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const companyGetter = new CompanyGetter({
    data: props.data,
    errorMsg: t('common.utils.unknown')
  });

  const balanceSheetType = companyGetter.getKeyFiguresOriginBalanceSheetType();

  const financialDatas = props.data.dataViews?.data?.company ? [
    {
      key: t('common.financial.turnover.short'),
      fullKey: t('common.financial.turnover.label'),
      value: companyGetter.getTurnover(),
      completeness: companyGetter.getTurnoverCompleteness(),
      date: companyGetter.getTurnoverDate(),
      align: 'right' as const
    },
    {
      key: t('common.financial.capital.label'),
      value: companyGetter.getCapital(),
      completeness: companyGetter.getCapitalCompleteness(),
      date: companyGetter.getCapitalDate(),
      align: 'right' as const
    },
    {
      key: t('common.financial.ebitda.label'),
      value: companyGetter.getEbitda(),
      completeness: companyGetter.getEbitdaCompleteness(),
      date: companyGetter.getEbitdaDate(),
      align: 'right' as const
    },
    {
      key: t('common.financial.valuation.short'),
      fullKey: t('common.financial.valuation.label'),
      value: companyGetter.getValuation(),
      completeness: companyGetter.getValuationCompleteness(),
      date: companyGetter.getValuationDate(),
      accuracy: companyGetter.getValuationAccuracy(),
      align: 'right' as const
    },
    {
      key: t('common.financial.originBalanceSheetType.short'),
      fullKey: t('common.financial.originBalanceSheetType.label'),
      value: t(`common.financial.originBalanceSheetType.types.${balanceSheetType ?? 'unknown'}`)
    }
  ] : [];

  return (
    <BaseWidget {...props} title={t('common.financial.label')}>
      <KeyValueWithCard
        values={[financialDatas]}
        keyValueCard={
          {
            Key: `${t('common.financial.turnover.label')}`,
            value: companyGetter.getTurnover(),
            size: 'big',
            bgColor: companyGetter.getTurnoverDate() ? Math.abs(new Date(Date.now() - (companyGetter.getTurnoverDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 1 ? theme.palette.success.light : Math.abs(new Date(Date.now() - (companyGetter.getTurnoverDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 2 ? theme.palette.warning.light : theme.palette.error.light : theme.primaryColors?.light || theme.palette.primary.light,
            color: companyGetter.getTurnoverDate() ? Math.abs(new Date(Date.now() - (companyGetter.getTurnoverDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 1 ? theme.palette.success.dark : Math.abs(new Date(Date.now() - (companyGetter.getTurnoverDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 2 ? theme.palette.warning.dark : theme.palette.error.dark : theme.palette.primary.dark
          }
        }
      />
    </BaseWidget>
  );
}

export default CompanyWidgetsFinancial;
