import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import {
  SegmentationData, SegmentationPossibleOutputEntityTypes, SegmentationRequest, SegmentationWorkflowIds
} from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import {
  IconCirclesRelation,
  IconFilterSearch,
  IconUsers
} from '@tabler/icons-react';
import {
  SegmentationCriteriaSpec,
  SegmentationFilter
} from '@deecision/dna-interfaces/dist/segmentation/segmentationFilters';
import Chip from '@mui/material/Chip';
import { Autocomplete, Box, lighten, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import { uniq } from 'lodash';
import TextField from '@mui/material/TextField';
import IconBuildings from '@/assets/custom/IconBuildings';
import { makeFindOptions } from '@/utils';
import SimpleDisplayFilters, { SimpleFilter } from '@/components/filters/simple';
import { BaseCustomUserData, BaseSegmentationProps, CustomSegmentationData, TmpFilterClassification } from '../types';
import SegmentationsServices from '../services';
import getDynamicGroupsRequest from '../../../modules/deetect/portfolios/utils/dynamicgroups';
import { SegmentationContext } from '../wrapper';
import {
  possibleCriteriaTypes,
  possibleOutputEntities,
  possibleWorkflowIds,
  segmentationPossibleWorkflows
} from '../builder/workflows';
import CustomAccordion from '../../../../components/accordion';
import RenderSegmentationCriterias from './criterias';
import EntitiesListsSegmentations from './lists/entities.lists.segmentations';
import FiltersCriteriasSegmentations from './criterias/filters';
import ConfigBuilderSegmentation from '../builder/config';
import PreFilteringBuilderSegmentation from '../builder/prefiltering';
import { getEntityTypeEquivalent } from '@/main/providers/getter';
import { SegmentationDataContext } from './abstract';
import SelectorLevelsRenderSegmentations from './levels/selector';
import SegmentationTitle from './title';
import LoadingList from '@/components/loading/list';
import { filterClassificationColorMapping } from '../components/classification.chips';

interface RenderSegmentationsProps {
  filters?: BaseSegmentationProps['filters'],
  removeFilter?: (filterId: string) => void,
  reset?: () => void,
  children?: React.ReactNode | React.ReactNode[]
}

export type FilterClassification = {
  filterName: string,
  active: boolean
}[]

function RenderSegmentations(props: RenderSegmentationsProps): ReactElement {
  const segmentationContext = useContext(SegmentationContext);
  const { id } = useParams();
  const theme = useTheme();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const segmentationService = new SegmentationsServices();
  const [listRef, setListRef] = useState<HTMLDivElement | null>(null);
  const [inputValues, setInputValues] = useState<string[]>([]);

  const entityType = searchParams.get('entityType');
  const groupId = searchParams.get('groupId');
  const customGroupId = searchParams.get('customGroupId');
  const outputEntitiesFromParams = searchParams.get('outputEntities');
  const workflowIdFromParams = searchParams.get('workflowId');
  const filterIds = searchParams.get('filterIds')?.split(',');
  const filterValues = searchParams.get('filterValues')?.split(',');
  const [classificationsFilters, setClassificationsFilters] = useState<SimpleFilter[]>(
    Object.values(TmpFilterClassification).map(key => ({
      label: t(`segmentation.render.classifications.${key}`),
      id: key,
      active: true,
      customColor: lighten(filterClassificationColorMapping[key], 0.7)
    }))
  );
  const getOutputEntities = () =>  (outputEntitiesFromParams ? [outputEntitiesFromParams as SegmentationRequest['outputEntities']] : entityType ? possibleOutputEntities([getEntityTypeEquivalent(entityType) as CustomSegmentationData['possibleDataSetIds'][0]]) : segmentationContext?.segmentation?.data.possibleOutputEntities || []);
  const getWorkflowIds = (dataSetIdTmp: CustomSegmentationData['possibleDataSetIds'][0], outputEntitiesTmp?: CustomSegmentationData['possibleOutputEntities'][0]) => (workflowIdFromParams ? [workflowIdFromParams as SegmentationRequest['workflowId']] : possibleWorkflowIds([dataSetIdTmp], outputEntitiesFromParams ? [outputEntitiesFromParams as SegmentationRequest['outputEntities']] : outputEntitiesTmp ? [outputEntitiesTmp] : []));
  const [outputEntities, setOutputEntities] = useState<SegmentationPossibleOutputEntityTypes>(getOutputEntities()[0]);
  const getCriterias = (outputEntitiesTmp?: SegmentationPossibleOutputEntityTypes) => segmentationContext?.segmentation?.data.potentialSegmentationCriterias
    .filter(criteria => possibleCriteriaTypes(undefined, outputEntitiesTmp ? [outputEntitiesTmp] : [outputEntities]).includes(criteria.on))
  // Retrieve only active classification criterias
    .filter(data => data.classifications?.some(classification => classificationsFilters
      .filter(filter => filter.active)
      .map(filter => filter.id).includes(classification))
    ) || [];
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(pathname.includes('create'));
  const [isPrefiltersEnabled, setIsPrefiltersEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [segmentationData, setSegmentationData] = useState<SegmentationData>();
  const [filters, setFilters] = useState<BaseSegmentationProps['filters']>([]);
  // Old ClassificationFilters with checkedBox
  // const [classificationsFilters, setClassificationsFilters] = useState<FilterClassification>(
  //   Object.values(TmpFilterClassification).map(key => ({
  //     filterName: key,
  //     active: true // The classification are always shown by default
  //   }))
  // );
  const [entryCount, setEntryCount] = useState<number>(0);
  const [dataSetId, setDataSetId] = useState<CustomSegmentationData['possibleDataSetIds'][0]>(entityType ? getEntityTypeEquivalent(entityType) as CustomSegmentationData['possibleDataSetIds'][0] : segmentationContext?.segmentation?.data.possibleDataSetIds[0] || 'deecPersons');
  const [workflowId, setWorkflowId] = useState<SegmentationRequest['workflowId']>(getWorkflowIds(dataSetId, outputEntities)[0]);

  const baseFilterGroupMember: SegmentationFilter[] = id ? (customGroupId === 'true' && groupId) ? getDynamicGroupsRequest(groupId, id)?.filters || [] : [
    {
      id: dataSetId === 'deecPersons' ? 'person_groupMember' : 'company_groupMember',
      filterId: dataSetId === 'deecPersons' ? 'person_groupMember' : 'company_groupMember',
      type: 'filter',
      on: dataSetId === 'deecPersons' ? 'person1' : 'company',
      values: groupId && groupId.split(',').length > 1 ? groupId.split(',').map(uniqGroupId => `${id}/${uniqGroupId}`) : [`${id}/${groupId || (dataSetId === 'deecPersons' ? 'persons' : 'companies')}`]
    }
  ] : [];

  const baseFiltersFromUrlParams: SegmentationFilter[] = filterIds && filterValues ? filterIds.map((filterId, index) => ({
    id: filterId,
    filterId,
    type: 'filter',
    on: dataSetId === 'deecPersons' ? 'person1' : 'company',
    values: [filterValues[index] === 'true' ? true : filterValues[index] === 'false' ? false : filterValues[index]]
  })) : [];

  const setCustomSegmentationData = (segmentationDataTmp: CustomSegmentationData) => {
    setIsDraft(true);
    segmentationContext?.setSegmentation(prevState =>
      (prevState
        ? {
          ...prevState,
          data: segmentationDataTmp
        }
        : undefined)
    );
  };

  const makeSegmentationCriterias = (criterias: CustomSegmentationData['potentialSegmentationCriterias'], filtersTmp: typeof filters) => criterias.map(criteria => ({
    ...criteria,
    type: 'segmentationCriteria',
    on: segmentationPossibleWorkflows
      .find(possibleWorkflow => possibleWorkflow.dataSetId === dataSetId)?.entryPoints
      .find(entryPoint => entryPoint.entryPoint === outputEntities)?.correlationTable?.[criteria.on] || criteria.on
  }))
    .filter(criteria => !filtersTmp.find(filter => filter.filterId === criteria.filterId && filter.on === criteria.on));

  const makeFilters = (filtersTmp: SegmentationFilter[]) => filtersTmp.map((filter) => {
    if (filter.on !== outputEntities && filter.on.includes('company') && outputEntities !== 'person2person') {
      return {
        id: `${filter.filterId || ''}-${filter.on || outputEntities}-withAnyCompany`,
        type: 'personWithAnyCompany' as const,
        on: outputEntities,
        subItemSpecs: [
          {
            ...filter,
            id: `${filter.filterId || ''}-${filter.on || outputEntities}`
          }
        ]
      };
    }

    return {
      ...filter,
      id: `${filter.filterId || ''}-${filter.on || outputEntities}`
    };
  });

  const performSegmentation = (params?: {
    outputEntities?: typeof outputEntities,
    filters?: typeof filters,
    searchParams?: typeof searchParams,
    workflowId?: typeof workflowId
  }) => {
    if (dataSetId && outputEntities && workflowId) {
      const request = {
        dataSetId,
        outputEntities: params?.outputEntities || outputEntities,
        workflowId: params?.workflowId || workflowId,
        filters: [
          ...baseFilterGroupMember,
          ...baseFiltersFromUrlParams,
          ...(segmentationContext?.segmentation?.data.preFilters.map(preFilter => ({ ...preFilter, id: preFilter.filterId })) as SegmentationRequest['filters'] || []),
          ...makeFilters(params?.filters || filters),
          ...props.filters || []
        ],
        globalFilteringItems: [],
        segmentationCriterias: makeSegmentationCriterias(getCriterias(params?.outputEntities || outputEntities), params?.filters || filters) as SegmentationCriteriaSpec[],
        entitiesSettings: {
          includeEntities: true,
          findOptions: makeFindOptions({ searchParams: params?.searchParams || searchParams, post: true })
        }
      };

      setIsLoading(true);
      segmentationService.perform(request)
        .then((res) => {
          setSegmentationData(res.data ? { ...res.data, request } : undefined);
          setIsLoading(false);
          if (!entryCount && res.data) {
            setEntryCount(res.data.totalCount);
          }
        });
    }
  };

  const changeFilters = (filtersTmp: typeof filters) => {
    if (searchParams.get('page')) {
      searchParams.delete('page');
      setSearchParams(searchParams);
    }

    setFilters(filtersTmp);
    performSegmentation({ filters: filtersTmp, searchParams });
  };

  const addFilter = (filter: typeof filters[0]) => {
    changeFilters([
      ...filters,
      {
        ...filter,
        id: `${filter.id || ''}-${makeSegmentationCriterias([filter as unknown as CustomSegmentationData['potentialSegmentationCriterias'][0]], filters)[0].on}`,
        on: makeSegmentationCriterias([filter] as unknown as CustomSegmentationData['potentialSegmentationCriterias'], filters)[0].on
      } as typeof filters[0]
    ]);
  };

  const removeFilter = (filterId: string) => {
    if (filters.find(filter => filter.id === filterId)) {
      changeFilters([
        ...filters.filter(f => f.id !== filterId)
      ]);
    } else {
      props.removeFilter?.(filterId);
    }
  };

  const reset = () => {
    setFilters([]);
    props.reset?.();
    setSegmentationData(undefined);
  };

  const changeIsEditMode = (isEditModeTmp: boolean) => {
    setIsEditMode(isEditModeTmp);
    if (isEditModeTmp) {
      setIsDraft(true);
    }
  };

  const changeWorkflowId = (workflowIdTmp: typeof workflowId) => {
    if (searchParams.get('page')) {
      searchParams.delete('page');
      setSearchParams(searchParams);
    }

    performSegmentation({ workflowId: workflowIdTmp, searchParams });
    setWorkflowId(workflowIdTmp);
  };

  const changeOutputEntities = (outputEntitiesTmp: typeof outputEntities) => {
    if (getWorkflowIds(dataSetId, outputEntitiesTmp)[0] !== workflowId) {
      changeWorkflowId(getWorkflowIds(dataSetId, outputEntitiesTmp)[0]);
    } else if (segmentationData) {
      if (searchParams.get('page')) {
        searchParams.delete('page');
        setSearchParams(searchParams);
      }
      performSegmentation({ outputEntities: outputEntitiesTmp, searchParams });
    }
    setOutputEntities(outputEntitiesTmp);
  };

  const changeCustomSegmentation = (customSegmentation: BaseCustomUserData<CustomSegmentationData> | undefined) => {
    setIsDraft(true);
    segmentationContext?.setSegmentation(customSegmentation);
  };

  // Old ClassificationFilters with checkedBox
  // const onClassificationFilterChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  //   setClassificationsFilters(prevState =>
  //     prevState.map(filter =>
  //       (filter.id === event.target.value
  //         ? { ...filter, active: checked }
  //         : filter)
  //     )
  //   );
  // };

  useEffect(() => {
    if (segmentationContext && segmentationContext?.segmentation?.data) {
      if (getOutputEntities()?.[0] !== outputEntities) {
        changeOutputEntities(getOutputEntities()?.[0]);
      } else if (searchParams.get('page')) {
        searchParams.delete('page');
        setSearchParams(searchParams);
      } else {
        performSegmentation({ searchParams });
      }
    }
  }, [id, props.filters, segmentationContext?.segmentation?.data.potentialSegmentationCriterias.length, segmentationContext?.segmentation?.lastUpdate]);

  useEffect(() => {
    performSegmentation({ searchParams });
  }, [searchParams, classificationsFilters]);

  return segmentationContext?.segmentation?.data ? (
    <SegmentationDataContext.Provider value={segmentationData || null}>
      <Grid container>
        {!props.children && (
          <SegmentationTitle
            segmentationData={segmentationData}
            isDraft={isDraft}
            setIsDraft={setIsDraft}
            isEditMode={isEditMode}
            setIsEditMode={changeIsEditMode}
            setSegmentationData={setSegmentationData}
          />
        )}
        <Grid
          size={12}
          pt={2}
          pb={2}
          alignItems='center'
          sx={
            [...filters, ...(props.filters || [])].length > 0
              ? {
                position: 'sticky',
                top: 53,
                bgcolor: theme.palette.grey['50'],
                zIndex: 3
              }
              : undefined
          }
        >
          <FiltersCriteriasSegmentations
            segmentationData={segmentationData}
            filters={[...filters, ...(props.filters || [])]}
            addFilter={addFilter}
            removeFilter={removeFilter}
            reset={reset}
            listRef={listRef}
          />
        </Grid>
        {outputEntities && getOutputEntities().length > 1 && !props.children &&
          <Grid size={12} pt={2} pb={2}>
            <SelectorLevelsRenderSegmentations
              possibleOutputEntities={getOutputEntities()}
              outputEntities={outputEntities}
              setOutputEntities={changeOutputEntities}
              setDataSetId={setDataSetId}
              first
            />
          </Grid>
        }
        <Grid size={12} pt={2} pb={2}>
          <Stack>
            {!props.children &&
              isEditMode &&
              segmentationContext.segmentation && [
              <ConfigBuilderSegmentation
                customSegmentation={segmentationContext.segmentation}
                setCustomSegmentation={changeCustomSegmentation}
                isPrefiltersEnabled={isPrefiltersEnabled}
                setIsPrefiltersEnabled={setIsPrefiltersEnabled}
                access={segmentationContext.segmentation.groups?.[0] || 'me'}
                setAccess={segmentationContext?.updateAccess}
              />,
              isPrefiltersEnabled && (
                <PreFilteringBuilderSegmentation
                  customSegmentationData={
                    segmentationContext.segmentation.data
                  }
                  setCustomSegmentationData={setCustomSegmentationData}
                />
              )
            ]}
            {props.children}
            {props.children && outputEntities && getOutputEntities().length > 1 &&
              <SelectorLevelsRenderSegmentations
                possibleOutputEntities={getOutputEntities()}
                outputEntities={outputEntities}
                setOutputEntities={changeOutputEntities}
                setDataSetId={setDataSetId}
                first={!props.children}
              />
            }
            <CustomAccordion
              title={
                <Stack
                  direction='row'
                  spacing={2}
                  alignItems='center'
                  justifyContent='space-between'
                  width='100%'
                  pr={4}
                >
                  <Stack
                    direction='row'
                    spacing={2}
                    alignItems='center'
                  >
                    <Typography variant='h4'>
                      {t('segmentation.render.segmentationCriterias.label')}
                    </Typography>
                    <Chip
                      label={
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <IconFilterSearch size='1rem' />
                          <Typography variant='body2'>
                            {`${getCriterias()?.length || 0} ${t('segmentation.render.segmentationCriterias.displayed')}`}
                          </Typography>
                        </Stack>
                      }
                      color='primary'
                      size='small'
                    />
                  </Stack>
                  <Stack direction='row' spacing={2} alignItems='center' onClick={ (e) => {
                    e.stopPropagation(); // Stop click propagation to children so that the clicks within it do not close the CustomAccordion here
                  }}>
                    <Box width={24} height={24}>
                      <IconFilterSearch size='1.6rem' />
                    </Box>
                    <Autocomplete
                      multiple
                      freeSolo
                      value={inputValues}
                      onChange={(event, newInputValue) => {
                        setInputValues(newInputValue);
                      }}
                      disablePortal
                      options={uniq(makeSegmentationCriterias(getCriterias(outputEntities), filters).map(criteria => criteria.filterLabel))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          maxRows={1}
                          label={t('segmentation.render.segmentationCriterias.search')}
                          fullWidth
                        />
                      )}
                      sx={{
                        p: 2,
                        mt: 2,
                        minWidth: 200
                      }}
                      fullWidth
                      size='small'
                    />
                    <SimpleDisplayFilters filters={classificationsFilters} setFilters={setClassificationsFilters} />
                    {/* Old ClassificationFilters with checkedBox */}
                    {/* <FiltersClassification
                      filters={classificationsFilters}
                      onClassificationFilterChange={onClassificationFilterChange}
                    /> */}
                  </Stack>
                </Stack>
              }
              bgcolor={theme.palette.background.default}
              defaultOpen
            >
              <RenderSegmentationCriterias
                getCriterias={getCriterias}
                setCustomSegmentationData={setCustomSegmentationData}
                updateSegmentation={performSegmentation}
                outputEntities={outputEntities}
                dataSetId={dataSetId}
                segmentationData={
                  isLoading
                    ? ({
                      ...segmentationData,
                      segmentationCriterias: []
                    } as unknown as SegmentationData)
                    : segmentationData
                }
                filters={filters}
                addFilter={addFilter}
                removeFilter={removeFilter}
                searchCriterias={inputValues}
              />
            </CustomAccordion>
            <CustomAccordion
              title={
                <Stack
                  direction='row'
                  spacing={2}
                  alignItems='center'
                  width='100%'
                  pr={4}
                >
                  <Typography variant='h4'>
                    {t('segmentation.render.entities.label')}
                  </Typography>
                  {segmentationData && !isLoading ? (
                    <Chip
                      label={
                        <Stack direction='row' spacing={1} alignItems='center'>
                          {workflowId === 'person1_link_person2' && (outputEntities === 'person2person' ?
                            <IconCirclesRelation size='1rem' /> :
                            <IconUsers size='1rem' />
                          )}
                          {workflowId === 'person1-companies_person2-companies' && <IconBuildings size='1rem' />}
                          <Typography variant='body2'>
                            {segmentationData?.totalCount || 0}
                          </Typography>
                        </Stack>
                      }
                      color='primary'
                      size='small'
                    />
                  ) : (
                    <Skeleton variant='rounded' width={64} height={24} />
                  )}
                  {workflowId &&
                    getWorkflowIds(dataSetId, outputEntities).length > 1 && (
                    <Select
                      native
                      variant='outlined'
                      value={workflowId}
                      onChange={event => changeWorkflowId(event.target.value as SegmentationWorkflowIds)
                      }
                      size='small'
                      onClick={event => event.stopPropagation()}
                      sx={{
                        ml: 'auto !important'
                      }}
                    >
                      {getWorkflowIds(dataSetId, outputEntities).map(wi => (
                        <option key={wi} value={wi}>
                          {t(`segmentation.render.entities.workflowIds.${wi}`)}
                        </option>
                      ))}
                    </Select>
                  )}
                </Stack>
              }
              bgcolor={theme.palette.background.default}
              setRef={setListRef}
              defaultOpen
            >
              {segmentationData && !isLoading ? (
                <EntitiesListsSegmentations
                  data={segmentationData}
                  entityType={segmentationData.objectType}
                />
              ) : <LoadingList />}
            </CustomAccordion>
          </Stack>
        </Grid>
      </Grid>
    </SegmentationDataContext.Provider>
  ) : (
    <></>
  );
}

export default RenderSegmentations;
