import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { useNavigate, useParams } from 'react-router-dom';
import { EntityType, GroupAllValues, SegmentationData, SegmentationRequest } from '@deecision/dna-interfaces';
import { Chip, useTheme } from '@mui/material';
import { DynamicGroupsData } from '@deecision/dna-interfaces/dist/groups/dynamicGroups';
import Box from '@mui/material/Box';
import { currencyFormatter } from '@/utils';
import GroupsServices, { DynamicGroupsServices } from '../../services';
import SegmentationsServices from '../../../../../containers/segmentations/services';
import dnaConfig from '../../../../../../config/dna.config.json';
import getDynamicGroupsRequest from '../../utils/dynamicgroups';
import BaseWidget from '@/main/containers/widgets/base-widget';
import TitleComponent from '@/components/title';
import getPercentage from '@/utils/getPercentage';

interface InfoValue {
  customSubGroupId?: boolean,
  subGroupId: string,
  entityType: EntityType,
  workflowId?: SegmentationRequest['workflowId']
}

interface UseCase {
  id: string,
  label: string,
  sides: {
    text1: string,
    text2: string,
    values1?: (React.ReactNode)[],
    values2?: (React.ReactNode)[],
    values1Infos?: InfoValue[],
    values2Infos?: InfoValue[]
  }[]
}

function UsecasesTabsPortfolios(): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [useCases, setUseCases] = useState<UseCase[]>([]);
  const { id } = useParams();
  const groupsServices = new GroupsServices();
  const dynamicGroupsServices = new DynamicGroupsServices();
  const segmentationsServices = new SegmentationsServices();
  const [values, setValues] = useState<GroupAllValues[]>([]);
  const [segmentationResults, setSegmentationResults] = useState<SegmentationData>();
  const [crossSalesGroup, setCrossSalesGroup] = useState<DynamicGroupsData>();
  const [familyClientsGroup, setFamilyClientsGroup] = useState<DynamicGroupsData>();
  const [networkClientsGroup, setNetworkClientsGroup] = useState<DynamicGroupsData>();

  const callSegmentation = (params: { entityType?: string, groupId?: string, workflowId?: string, customGroupId?: boolean }) => {
    if (params.groupId && params.entityType) {
      navigate(`../${dnaConfig.routes.portfolios.childrens.augment.baseUrl}?entityType=${params.entityType}&groupId=${params.groupId}${params.workflowId ? `&workflowId=${params.workflowId}` : ''}${params.customGroupId ? `&customGroupId=${params.customGroupId}` : ''}`);
    }
  };

  const getValues = () => {
    if (!id) return;
    const customCrossSalesGroup = getDynamicGroupsRequest('crossSells', id);
    const customFamilyClientsGroup = getDynamicGroupsRequest('familyClients', id);
    const customNetworkClientsGroup = getDynamicGroupsRequest('networkClients', id);

    groupsServices.getAllGroupValues(id)
      .then(res => setValues(res.data || []));

    if (customCrossSalesGroup) {
      dynamicGroupsServices.getDynamicGroupValues(customCrossSalesGroup)
        .then(res => setCrossSalesGroup(res.data));
    }

    if (customFamilyClientsGroup) {
      dynamicGroupsServices.getDynamicGroupValues(customFamilyClientsGroup)
        .then(res => setFamilyClientsGroup(res.data));
    }

    if (customNetworkClientsGroup) {
      dynamicGroupsServices.getDynamicGroupValues(customNetworkClientsGroup)
        .then(res => setNetworkClientsGroup(res.data));
    }

    segmentationsServices.perform({
      dataSetId: 'deecPersons',
      globalFilteringItems: [],
      filters: [
        {
          id: 'person_groupMember',
          filterId: 'person_groupMember',
          type: 'filter',
          on: 'person1',
          values: [
            `${id}/persons.withCompanies`
          ]
        },
        {
          id: 'person_isClient',
          filterId: 'person_isClient',
          type: 'filter',
          on: 'person1',
          values: [ true ]
        }
      ],
      entitiesSettings: {
        includeEntities: false,
        findOptions: {}
      },
      outputEntities: 'person1',
      segmentationCriterias: [
        {
          id: 'person_veegilenz_global',
          filterId: 'person_veegilenz_global',
          on: 'person1',
          type: 'segmentationCriteria'
        },
        {
          id: 'person_completeness',
          filterId: 'person_completeness',
          on: 'person1',
          type: 'segmentationCriteria'
        }
      ]
    })
      .then(res => setSegmentationResults(res.data));
  };

  const getUseCases = () => {
    setUseCases([
      {
        id: 'crossSales',
        label: t(`portfolios.useCases.cases.crossSales.label`),
        sides: [
          {
            text1: 'xx%',
            text2: t(`portfolios.useCases.cases.crossSales.desc`),
            values1: [
              getPercentage(crossSalesGroup?.values?.totalCount || 0, values.find(value => value.groupDesc.subGroupId === 'companies.persons')?.values.totalCount || 0)
            ],
            values1Infos: [
              {
                customSubGroupId: true,
                subGroupId: 'crossSells',
                entityType: 'deecPerson'
              }
            ]
          },
          {
            text1: t(`portfolios.useCases.cases.crossSales.desc2`),
            text2: 'xx',
            values2: [
              currencyFormatter({ value: crossSalesGroup?.values?.main?.weasse_sum || 0, currency: 'EUR' })
            ],
            values2Infos: [
              {
                customSubGroupId: true,
                subGroupId: 'crossSells',
                entityType: 'deecPerson'
              }
            ]
          }
        ]
      },
      {
        id: 'additionalSalesPotential',
        label: t(`portfolios.useCases.cases.additionalSalesPotential.label`),
        sides: [
          {
            text1: 'xx',
            text2: t(`portfolios.useCases.cases.additionalSalesPotential.desc`),
            values1: [
              currencyFormatter({ value: values.find(value => value.groupDesc.subGroupId === 'persons.withCompanies')?.values.main.weasse_sum || 0, currency: 'EUR' })
            ],
            values2: [
              values.find(value => value.groupDesc.subGroupId === 'persons.withCompanies')?.values.totalCount
            ],
            values1Infos: [
              {
                subGroupId: 'persons.withCompanies',
                entityType: 'deecPerson'
              }
            ],
            values2Infos: [
              {
                subGroupId: 'persons.withCompanies',
                entityType: 'deecPerson'
              }
            ]
          }
        ]
      },
      {
        id: 'familyGroups',
        label: t(`portfolios.useCases.cases.familyGroups.label`),
        sides: [
          {
            text1: 'xx - xx',
            text2: t(`portfolios.useCases.cases.familyGroups.desc`),
            values1: [
              currencyFormatter({ value: values.find(value => value.groupDesc.subGroupId === 'persons.network.personal.notClient.sameFamily')?.values.main.weasse_sum || 0, currency: 'EUR' }),
              currencyFormatter({ value: values.find(value => value.groupDesc.subGroupId === 'persons.network.business.notClient.sameFamily')?.values.main.weasse_sum || 0, currency: 'EUR' })
            ],
            values2: [
              values.find(value => value.groupDesc.subGroupId === 'persons.network.personal.notClient.sameFamily')?.values.totalCount,
              values.find(value => value.groupDesc.subGroupId === 'persons.network.business.notClient.sameFamily')?.values.totalCount
            ],
            values1Infos: [
              {
                subGroupId: 'persons.network.personal.notClient.sameFamily',
                entityType: 'deecPerson'
              },
              {
                subGroupId: 'persons.network.business.notClient.sameFamily',
                entityType: 'deecPerson'
              }
            ],
            values2Infos: [
              {
                subGroupId: 'persons.network.personal.notClient.sameFamily',
                entityType: 'deecPerson'
              },
              {
                subGroupId: 'persons.network.business.notClient.sameFamily',
                entityType: 'deecPerson'
              }
            ]
          },
          {
            text1: 'xx',
            text2: t(`portfolios.useCases.cases.familyGroups.desc2`),
            values1: [
              currencyFormatter({ value: familyClientsGroup?.values.companies?.valuationSum || 0, currency: 'EUR' })
            ],
            values2: [
              familyClientsGroup?.values.companies?.count || 0,
              familyClientsGroup?.values.totalCount || 0
            ],
            values1Infos: [
              {
                customSubGroupId: true,
                subGroupId: 'familyClients',
                entityType: 'deecPerson',
                workflowId: 'person1-companies_person2-companies'
              }
            ],
            values2Infos: [
              {
                customSubGroupId: true,
                subGroupId: 'familyClients',
                entityType: 'deecPerson',
                workflowId: 'person1-companies_person2-companies'
              },
              {
                customSubGroupId: true,
                subGroupId: 'familyClients',
                entityType: 'deecPerson'
              }
            ]
          }
        ]
      },
      {
        id: 'industrializeCo-option',
        label: t(`portfolios.useCases.cases.industrializeCo-option.label`),
        sides: [
          {
            text1: 'xx',
            text2: t(`portfolios.useCases.cases.industrializeCo-option.desc`),
            values1: [
              currencyFormatter({ value: values.find(value => value.groupDesc.subGroupId === 'persons.network.personal.notClient')?.values.main.weasse_sum || 0, currency: 'EUR' })
            ],
            values2: [
              values.find(value => value.groupDesc.subGroupId === 'persons.network.personal.notClient')?.values.totalCount
            ],
            values1Infos: [
              {
                subGroupId: 'persons.network.personal.notClient',
                entityType: 'deecPerson'
              }
            ],
            values2Infos: [
              {
                subGroupId: 'persons.network.personal.notClient',
                entityType: 'deecPerson'
              }
            ]
          },
          {
            text1: 'xx',
            text2: t(`portfolios.useCases.cases.industrializeCo-option.desc2`),
            values1: [
              currencyFormatter({ value: values.find(value => value.groupDesc.subGroupId === 'persons.network.business.notClient')?.values.companies?.valuationSum || 0, currency: 'EUR' })
            ],
            values2: [
              values.find(value => value.groupDesc.subGroupId === 'persons.network.business.notClient')?.values.companies?.count,
              values.find(value => value.groupDesc.subGroupId === 'persons.network.personal.notClient')?.values.totalCount
            ],
            values1Infos: [
              {
                subGroupId: 'persons.network.business.notClient',
                entityType: 'deecPerson',
                workflowId: 'person1-companies_person2-companies'
              }
            ],
            values2Infos: [
              {
                subGroupId: 'persons.network.business.notClient',
                entityType: 'deecPerson',
                workflowId: 'person1-companies_person2-companies'
              },
              {
                subGroupId: 'persons.network.business.notClient',
                entityType: 'deecPerson'
              }
            ]
          }
        ]
      },
      {
        id: 'identification',
        label: t(`portfolios.useCases.cases.identification.label`),
        sides: [
          {
            text1: 'xx',
            text2: t(`portfolios.useCases.cases.identification.desc`),
            values1: [
              currencyFormatter({ value: values.find(value => value.groupDesc.subGroupId === 'persons.withSCI')?.values.main.weasse_sum, currency: 'EUR' })
            ],
            values2: [
              values.find(value => value.groupDesc.subGroupId === 'persons.withSCI')?.values.totalCount
            ],
            values1Infos: [
              {
                subGroupId: 'persons.withSCI',
                entityType: 'deecPerson'
              }
            ],
            values2Infos: [
              {
                subGroupId: 'persons.withSCI',
                entityType: 'deecPerson'
              }
            ]
          },
          {
            text1: 'xx',
            text2: t(`portfolios.useCases.cases.identification.desc2`),
            values1: [
              currencyFormatter({ value: values.find(value => value.groupDesc.subGroupId === 'persons.withSCI')?.values.companies?.valuationSum, currency: 'EUR' })
            ]
          }
        ]
      },
      {
        id: 'events',
        label: t(`portfolios.useCases.cases.events.label`),
        sides: [
          {
            text1: 'xx',
            text2: t(`portfolios.useCases.cases.events.desc`),
            values1: [
              values.find(value => value.groupDesc.subGroupId === 'companies.withSoldCompanies.12months')?.values.totalCount
            ],
            values1Infos: [
              {
                subGroupId: 'companies.withSoldCompanies.12months',
                entityType: 'deecCompany'
              }
            ]
          },
          {
            text1: 'xx',
            text2: t(`portfolios.useCases.cases.events.desc2`)
          }
        ]
      },
      {
        id: 'consistency',
        label: t(`portfolios.useCases.cases.consistency.label`),
        sides: [
          {
            text1: 'xx',
            text2: t(`portfolios.useCases.cases.consistency.desc`),
            values1: [
              `${((100 / (values.find(value => value.groupDesc.subGroupId === 'persons')?.values.totalCount || 1)) * (values.find(value => value.groupDesc.subGroupId === 'persons.withoutCompanies')?.values.totalCount || 0)).toFixed(2)}%`
            ],
            values2: [
              values.find(value => value.groupDesc.subGroupId === 'persons.withoutCompanies')?.values.totalCount || 0
            ],
            values1Infos: [
              {
                subGroupId: 'persons.withoutCompanies',
                entityType: 'deecPerson'
              }
            ],
            values2Infos: [
              {
                subGroupId: 'persons.withoutCompanies',
                entityType: 'deecPerson'
              }
            ]
          }
        ]
      },
      {
        id: 'compliance',
        label: t(`portfolios.useCases.cases.compliance.label`),
        sides: [
          {
            text1: 'xx%',
            text2: t(`portfolios.useCases.cases.compliance.desc`),
            values1: [
              getPercentage(segmentationResults?.segmentationCriterias.person_completeness?.values?.filter(value => (value.range?.max || 0) <= 0.25).reduce((acc, curr) => (acc || 0) + (curr.count || 0), 0), segmentationResults?.totalCount || 0)
            ],
            values1Infos: [
              {
                customSubGroupId: true,
                subGroupId: 'completenessSub25',
                entityType: 'deecPerson'
              }
            ],
            values2: [
              segmentationResults?.segmentationCriterias.person_completeness?.values?.filter(value => (value.range?.max || 0) <= 0.25).reduce((acc, curr) => (acc || 0) + (curr.count || 0), 0),
              '25%'
            ],
            values2Infos: [
              {
                customSubGroupId: true,
                subGroupId: 'completenessSub25',
                entityType: 'deecPerson'
              },
              {
                customSubGroupId: true,
                subGroupId: 'completenessSub25',
                entityType: 'deecPerson'
              }
            ]
          },
          {
            text1: 'xx',
            text2: t(`portfolios.useCases.cases.compliance.desc2`),
            values1: [
              segmentationResults?.segmentationCriterias.person_veegilenz_global?.values?.map(value => ((!value.value || value.value === 'green') ? 0 : value.count)).reduce((acc, curr) => (acc || 0) + (curr || 0), 0)
            ]
          },
          {
            text1: 'xx',
            text2: t(`portfolios.useCases.cases.compliance.desc3`),
            values1: [
              networkClientsGroup?.values.totalCount
            ],
            values1Infos: [
              {
                customSubGroupId: true,
                subGroupId: 'networkClients',
                entityType: 'deecPerson'
              }
            ]
          },
          {
            text1: 'xx',
            text2: t(`portfolios.useCases.cases.compliance.desc4`),
            values1: [
              values.find(value => value.groupDesc.subGroupId === 'persons.withCompaniesWithAlerts.12months')?.values.totalCount
            ],
            values1Infos: [
              {
                subGroupId: 'persons.withCompaniesWithAlerts.12months',
                entityType: 'deecPerson'
              }
            ]
          }
        ]
      }
    ]);
  };

  useEffect(() => {
    getUseCases();
  }, [t, values, segmentationResults, crossSalesGroup]);

  useEffect(() => {
    getValues();
  }, [id]);

  return (
    useCases.length > 0 ?
      <Grid container spacing={4}>
        {useCases.map(useCase => (
          <Grid key={useCase.id} size={6} minWidth='min-content'>
            <BaseWidget
              title={
                <Box sx={{ ml: '-8px' }}>
                  <TitleComponent
                    title={
                      <Chip
                        label={useCase.label}
                        sx={{
                          '& .MuiChip-label': {
                            fontSize: '1rem'
                          },
                          bgcolor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText
                        }}
                      />
                    }
                    size='small'
                  />
                </Box>
              }
              fullHeight
              fullWidth
            >
              <Grid container spacing={4} width='100%' alignItems='center' justifyContent='space-evenly'>
                {useCase.sides.map(side => (
                  <Grid size={useCase.sides.length > 1 ? 6 : 12} key={`${useCase.id}-${side.text1}`}>
                    <Stack spacing={2} alignItems='center' justifyContent='center'>
                      <Typography
                        textAlign='center'
                        variant={side.text1.length > 10 ? 'body1' : 'h2'}
                        color={side.text1.length > 10 ? undefined : 'primary'}
                      >
                        {side.text1.split('xx').map((text, index) => (
                          [
                            text,
                            (side.values1?.[index] !== undefined ?
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                              <span
                                style={{ cursor: side.values1Infos?.[index] ? 'pointer' : undefined, color: theme.palette.primary.main }}
                                onClick={() => callSegmentation({ entityType: side.values1Infos?.[index]?.entityType, groupId: side.values1Infos?.[index]?.subGroupId, workflowId: side.values1Infos?.[index]?.workflowId, customGroupId: side.values1Infos?.[index]?.customSubGroupId })}
                              >
                                {side.values1?.[index]}
                              </span> :
                              (index > 0 && (index + 1) >= side.text1.split('xx').length || !side.text1.includes('xx')) ? '' : '-')
                          ]
                        ))}
                      </Typography>
                      <Typography
                        textAlign='center'
                        variant={side.text2.length > 10 ? 'body1' : 'h2'}
                        color={side.text2.length > 10 ? undefined : 'primary'}
                      >
                        {side.text2.split('xx').map((text, index) => (
                          [
                            text,
                            (side.values2?.[index] !== undefined ?
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                              <span
                                style={{ cursor: side.values2Infos?.[index] ? 'pointer' : undefined, color: theme.palette.primary.main }}
                                onClick={() => callSegmentation({ entityType: side.values2Infos?.[index]?.entityType,  groupId: side.values2Infos?.[index]?.subGroupId, workflowId: side.values2Infos?.[index]?.workflowId,  customGroupId: side.values2Infos?.[index]?.customSubGroupId })}
                              >
                                {side.values2?.[index]}
                              </span> :
                              (index > 0 && (index + 1) >= side.text2.split('xx').length || !side.text2.includes('xx')) ? '' : '-')
                          ]
                        ))}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </BaseWidget>
          </Grid>
        ))}
      </Grid> :
      <></>
  );
}

export default UsecasesTabsPortfolios;
