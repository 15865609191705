import React, { ReactElement, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { CompanyEntity } from '@deecision/dna-interfaces';
import {
  IconBuildingEstate,
  IconDotsVertical,
  IconDownload,
  IconFolderPlus,
  IconScreenshot
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TitleComponent from '@/components/title';
import { CompanyGetter } from '@/main/providers/getter';
import dnaConfig from '../../../../../config/dna.config.json';
import { DataElementsQueryService } from '@/api/services/query';
import CompanyEntitiesChips from '@/main/containers/chips/company';
import { tabsFunctions } from './tabs/utils/functions';
import CompanyWidgetsIdentity from '@/main/containers/widgets/companies/identity';
import CompanyWidgetsFinancial from '@/main/containers/widgets/companies/financial';
import CompanyWidgetsVeegilenz from '@/main/containers/widgets/companies/veegilenz';
import CompanyWidgetsBodacc from '@/main/containers/widgets/companies/bodacc';
import WidgetsNews from '@/main/containers/widgets/news';
import captureScreenshot from '@/utils/screenshot';
import { useFullContainerRef } from '@/hooks/full-container';

function CompanyComponent(): ReactElement {
  const data = useLoaderData() as { data : CompanyEntity };
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ref = useFullContainerRef();
  const companyGetter = new CompanyGetter({
    data: data.data,
    errorMsg: t('common.utils.unknown')
  });
  const [dynamicTabsLinkEnabled, setDynamicTabsLinkEnabled] = useState({
    [dnaConfig.routes.companies.tabs.officialInformations.baseUrl]: '',
    [dnaConfig.routes.companies.tabs.financial.baseUrl]: '',
    [dnaConfig.routes.companies.tabs.news.baseUrl]: '',
    [dnaConfig.routes.companies.tabs.veegilenz.baseUrl]: ''
  });

  const getDisplayTabsInfo = () => {
    new DataElementsQueryService({ entityType: 'deecCompany' }).get(data.data.entityId)
      .then((res) => {
        const dynamicTabsLinkEnabledTemp = { ...dynamicTabsLinkEnabled };

        Object.keys(dynamicTabsLinkEnabledTemp).forEach((key) => {
          if (tabsFunctions?.[key]) {
            tabsFunctions?.[key]?.({ id: data.data.entityId, data: res.data })
              .then((r) => {
                if (r === 'visible') {
                  dynamicTabsLinkEnabledTemp[key] = key;
                }
              });
          } else {
            dynamicTabsLinkEnabledTemp[key] = key;
          }
        });

        setDynamicTabsLinkEnabled(dynamicTabsLinkEnabledTemp);
      });
  };

  useEffect(() => {
    getDisplayTabsInfo();
  }, [data.data, t]);

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid>
        <TitleComponent title={data.data.name} icon={IconBuildingEstate} chips={CompanyEntitiesChips({ entity: data.data })} />
      </Grid>
      {/*
      <Grid>
        <Link href={dnaConfig.routes.companies.tabs.origeen.baseUrl}>
          <Completeness
            completeness={completenessArray.reduce((a, b) => (Number.isNaN(a) ? 0 : a) + (Number.isNaN(b) ? 0 : b), 0) / completenessArray.length}
            size='medium'
            icon={IconDatabase}
          />
        </Link>
      </Grid>
      */}
      <Grid ml='auto'>
        <Stack spacing={2} direction='row' alignItems='center'>
          <Button
            variant='text'
            startIcon={<IconDownload size='1.2rem' />}
            onClick={() => window.open(`https://data.inpi.fr/export/companies?format=pdf&ids=["${companyGetter.getSiren()}"]`, '_blank', '')}
          >
            {t('common.documents.officialIdentityReport')}
          </Button>
          <Button
            variant='contained'
            startIcon={<IconFolderPlus size='1.2rem' />}
            href={dnaConfig.routes.folders.create.steps.choices}
          >
            {t('folder.createFolder')}
          </Button>
          <IconButton
            id='menu-button'
            aria-controls={open ? 'menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={event => setAnchorEl(event.currentTarget)}
          >
            <IconDotsVertical size='1.2rem' />
          </IconButton>
          <Menu
            id='menu'
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'menu-button'
            }}
          >
            <MenuItem onClick={() => {
              captureScreenshot(ref);
              setAnchorEl(null);
            }} sx={{ p: 2 }}>
              <Stack direction='row' spacing={2} alignItems='center'>
                <IconScreenshot size='1.4rem' />
                <Typography>
                  {t('common.utils.captureScreenshot')}
                </Typography>
              </Stack>
            </MenuItem>
          </Menu>
        </Stack>
      </Grid>
      <Grid size={12} overflow='auto'>
        <Stack spacing={2} direction='row' alignItems='stretch' justifyContent='stretch' flexWrap='nowrap' width='max-content' pb={2}>
          <CompanyWidgetsIdentity data={data.data} />
          <CompanyWidgetsVeegilenz data={data.data} link={dynamicTabsLinkEnabled[dnaConfig.routes.companies.tabs.veegilenz.baseUrl]} />
          <CompanyWidgetsFinancial data={data.data} link={dynamicTabsLinkEnabled[dnaConfig.routes.companies.tabs.financial.baseUrl]} />
          <CompanyWidgetsBodacc data={data.data} link={dynamicTabsLinkEnabled[dnaConfig.routes.companies.tabs.officialInformations.baseUrl]} />
          <WidgetsNews data={data.data} link={dynamicTabsLinkEnabled[dnaConfig.routes.companies.tabs.news.baseUrl]} />
        </Stack>
      </Grid>
    </Grid>
  );
}

export default CompanyComponent;
