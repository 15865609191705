import React, { ReactElement, ReactNode, useMemo } from 'react';
import { FinancialReportDataBlock, IDataBlock } from '@deecision/dna-interfaces';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import Stack from '@mui/material/Stack';
import { IconAdjustmentsHorizontal, IconChartHistogram } from '@tabler/icons-react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { getFinancialYears } from '@/main/containers/financial/utils';
import TitleComponent from '@/components/title';
import BaseWidget from '@/main/containers/widgets/base-widget';
import BarCharts from '@/components/charts/bar';

export const baseSelectedKeys: string[] = ['profitAndLossAccount.turnover', 'lines.ebitda', 'liabilitiesAndEquity.capital'];

function FinancialChart(props: { data: IDataBlock<FinancialReportDataBlock>[], selectedKey: string[], setSelectedKey: (keyPath: string[]) => void, pathList: Record<string, string[]>, actions?: ReactNode  }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const years = useMemo(() => getFinancialYears(props.data), [props.data]);

  const data = useMemo(() => props.selectedKey.map(selectedKey => years.map((year) => {
    const obj = {
      value: year,
      count: 0
    };

    obj.count = get(props.data.find(d => year === new Date(d.data.closingDate).getFullYear().toString())?.data.details, selectedKey);

    return obj;
  })), [props.data, props.selectedKey]);

  return (
    <BaseWidget
      title={<TitleComponent title={t('common.financial.label')} icon={IconChartHistogram} small />}
      action={
        <Stack direction='row' spacing={2} alignItems='center'>
          {props.actions}
          <IconButton
            id='financial-chart-button'
            aria-controls={open ? 'financial-chart-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <IconAdjustmentsHorizontal color={theme.palette.text.primary} />
          </IconButton>
          <Menu
            id='financial-chart-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'financial-chart-button'
            }}
          >
            <Stack spacing={2} p={2}>
              {Object.keys(props.pathList).map(key => ([
                <Typography key={key} pl={1} pr={1} variant='body1' sx={{ fontWeight: 'bold' }}>{key.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() || ''}{key.replace(/([A-Z])/g, ' $1').slice(1) || ''}</Typography>,
                props.pathList[key].map(subKey => (
                  <FormGroup key={subKey} sx={{ minWidth: 'max-content', width: '100%', pr: 4, pl: 4 }}>
                    <FormControlLabel
                      labelPlacement='start'
                      control={
                        <Switch checked={props.selectedKey.includes(subKey)}
                          onChange={() => props.setSelectedKey(props.selectedKey.find(k => k === subKey) ? props.selectedKey.filter(k => k !== subKey) : [...props.selectedKey, subKey])}
                          size='small'
                          sx={{ ml: 'auto' }}
                        />
                      }
                      label={`${subKey.split('.')[1]?.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() || ''}${subKey.split('.')[1]?.replace(/([A-Z])/g, ' $1').slice(1) || ''}`}
                    />
                  </FormGroup>
                ))
              ]))}
            </Stack>
          </Menu>
        </Stack>
      }
      chart
      fullWidth
    >
      <Box width='100%' height='100%'>
        <BarCharts
          datas={data}
          totalCount={data[0]?.reduce((a, d) => a + d.count, 0)}
          countType='amount'
          legend
          line
          legends={props.selectedKey.map(key => `${key.split('.')[1]?.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() || ''}${key.split('.')[1]?.replace(/([A-Z])/g, ' $1').slice(1) || ''}`)}
        />
      </Box>
    </BaseWidget>
  );
}

export default FinancialChart;
