import React, { ReactElement, useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { ContainerRefProvider } from '@/hooks/container';
import { FullContainerRefProvider } from '@/hooks/full-container';

export interface MainerContainerProps {
  elevation?: number,
  breadcrumbs?: ReactElement,
  children: ReactElement
}

function Container(props: MainerContainerProps): ReactElement {
  const ref = useRef<null | HTMLDivElement>(null);
  const fullRef = useRef<null | HTMLDivElement>(null);

  return (
    <Grid size='grow' height='calc(100vh - 66px)'>
      <Box
        bgcolor='grey.50'
        width='calc(100% - 12px)'
        height='calc(100% - 12px)'
        borderRadius={1}
        sx={{
          boxShadow: (props.elevation && props.elevation * -1 < 0) ?
            'inset 0px 0px 4px rgba(0, 0, 0, 0.02), inset 1px 1px 5px rgba(0, 0, 0, 0.05)' :
            undefined
        }}
      >
        <Grid container height='100%' pb={24}>
          {props.breadcrumbs &&
              <Grid
                size={12}
                p={4}
                pb={0}
              >
                {props.breadcrumbs}
              </Grid>
          }
          <Grid size='grow' p={1} pt={0} height='calc(100% - 52px)' overflow='auto' ref={ref}>
            <Box ref={fullRef} height='max-content' p={3} pt={2}>
              <ContainerRefProvider value={ref}>
                <FullContainerRefProvider value={fullRef}>
                  {props.children}
                </FullContainerRefProvider>
              </ContainerRefProvider>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

export default Container;
