import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { isEmpty } from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import { TFunction } from 'i18next';
import { AugmentedEntity } from '@/main/providers/getter';

interface AugmentedPositionType {
    positionType: string,
    startDate?: Date,
    endDate?: Date,
    active: boolean
}

function generateTooltipContent(positionType: AugmentedPositionType, t: TFunction): string {
  const parts: string[] = [];
  if (positionType.startDate) {
    parts.push(`${t(`common.utils.estimatedStartDate`)}:  ${positionType.startDate.toLocaleDateString()}`);
  }
  if (positionType.endDate) {
    parts.push(`${t(`common.utils.estimatedEndDate`)}:  ${positionType.endDate.toLocaleDateString()}`);
  }

  return parts.join(' - ');
}

function generateAugmentedPositionTypes(entity: AugmentedEntity): AugmentedPositionType[] {
  const positionMap: { [key: string]: AugmentedPositionType } = {};

  entity.details.forEach((details) => {
    if (details) {
      const positionTypes = details?.positionTypes ?? [];

      const detailsAsAny = details;
      const startDateStr = (detailsAsAny as { startDate?: string }).startDate ?? (detailsAsAny as { firstSeenDate?: string }).firstSeenDate;
      const endDateStr = (detailsAsAny as { endDate?: string }).endDate ?? (detailsAsAny as { firstMissingDate?: string }).firstMissingDate;
      const startDate = startDateStr ? new Date(startDateStr) : undefined;
      const endDate = endDateStr ? new Date(endDateStr) : undefined;
      const active = isEmpty(endDateStr);

      positionTypes.forEach((positionType) => {
        const existing = positionMap[positionType];

        if (existing) {
          // on prend le status de la plus à jour
          if (existing.startDate && startDate && existing.startDate >= startDate) {
            // l'existante est deja à jour => on ne fait rien
          } else {
            existing.startDate = startDate;
            existing.endDate = endDate;
            existing.active = active;
          }
        } else {
          // on l'ajoute
          positionMap[positionType] = {
            positionType,
            active,
            startDate,
            endDate
          };
        }
      });
    }
  });

  return Object.values(positionMap);
}

function RelationPositionTypeChips(props: { entity: AugmentedEntity }): ReactElement {
  const { t } = useTranslation();

  const positionTypes: AugmentedPositionType[] = useMemo(() => generateAugmentedPositionTypes(props.entity), [props.entity]);

  return (
    <Stack direction='row' spacing={2} maxWidth={340} flexWrap='wrap' useFlexGap>
      {
        positionTypes.map(positionType =>
        // TODO: make the toolip
        // TODO: if not active change the style and strikeTrough
          <Tooltip key='amf-chip' title={generateTooltipContent(positionType, t)} arrow placement='top'>
            <Chip
              key={positionType.positionType} size='small' label={t(`common.positionTypes.${positionType.positionType}`)}
              color={positionType.active ? 'default' : 'error'}
              style={{ textDecoration: positionType.active ? 'none' : 'line-through' }}
            />
          </Tooltip>

        )
      }
    </Stack>
  );
}

export default RelationPositionTypeChips;
