import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { isEmpty } from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import { TFunction } from 'i18next';
import { AugmentedEntity } from '@/main/providers/getter';

interface AugmentedRole {
    role: string,
    startDate?: Date,
    endDate?: Date,
    active: boolean
}

function generateTooltipContent(positionType: AugmentedRole, t: TFunction): string {
  const parts: string[] = [];
  if (positionType.startDate) {
    parts.push(`${t(`common.utils.estimatedStartDate`)}:  ${positionType.startDate.toLocaleDateString()}`);
  }
  if (positionType.endDate) {
    parts.push(`${t(`common.utils.estimatedEndDate`)}:  ${positionType.endDate.toLocaleDateString()}`);
  }

  return parts.join(' - ');
}

function generateAugmentedPositionTypes(entity: AugmentedEntity): AugmentedRole[] {
  const roleMap: { [key: string]: AugmentedRole } = {};

  entity.details.forEach((details) => {
    if (details?.role) {
      const roleText = details.role.text;

      const detailsAsAny = details;
      const startDateStr = (detailsAsAny as { startDate?: string }).startDate ?? (detailsAsAny as { firstSeenDate?: string }).firstSeenDate;
      const endDateStr = (detailsAsAny as { endDate?: string }).endDate ?? (detailsAsAny as { firstMissingDate?: string }).firstMissingDate;
      const startDate = startDateStr ? new Date(startDateStr) : undefined;
      const endDate = endDateStr ? new Date(endDateStr) : undefined;
      const active = isEmpty(endDateStr);

      const existing = roleMap[roleText];

      if (existing) {
        // on prend le status de la plus à jour
        if (existing.startDate && startDate && existing.startDate >= startDate) {
          // l'existante est deja à jour => on ne fait rien
        } else {
          existing.startDate = startDate;
          existing.endDate = endDate;
          existing.active = active;
        }
      } else {
        // on l'ajoute
        roleMap[roleText] = {
          role: roleText,
          active,
          startDate,
          endDate
        };
      }
    }
  });

  return Object.values(roleMap);
}

function RelationRoleChips(props: { entity: AugmentedEntity }): ReactElement {
  const { t } = useTranslation();
  const roles: AugmentedRole[] = useMemo(() => generateAugmentedPositionTypes(props.entity), [props.entity]);

  return <Stack direction='row' spacing={2} maxWidth={340} flexWrap='wrap' useFlexGap>
    {
      roles.map(role => <Tooltip key='amf-chip' title={generateTooltipContent(role, t)} arrow placement='top'>
        <Chip
          key={role.role} size='small' label={role.role}
          color={role.active ? 'default' : 'error'}
          style={{ textDecoration: role.active ? 'none' : 'line-through' }}
        />
      </Tooltip>
      )
    }
  </Stack>;
}

export default RelationRoleChips;
