import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { isEmpty } from 'lodash';
import { AugmentedEntity } from '@/main/providers/getter';

enum RelationStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    UNKNOWN = 'unknown'
}

const RelationStatusToColor: Record<RelationStatus, 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'> = {
  [RelationStatus.ACTIVE]: 'success',
  [RelationStatus.INACTIVE]: 'error',
  [RelationStatus.UNKNOWN]: 'default'
};

function generateStatus(entity: AugmentedEntity): RelationStatus {

  const status = entity.details
    .map((details) => {
      if (!details) {
        // some details have no data
        return RelationStatus.UNKNOWN;
      }
      // const startDateStr = (details as { startDate?: string }).startDate ?? (details as { firstSeenDate?: string }).firstSeenDate;
      const endDateStr = (details as { endDate?: string }).endDate ?? (details as { firstMissingDate?: string }).firstMissingDate;
      const active = isEmpty(endDateStr);

      return active ? RelationStatus.ACTIVE : RelationStatus.INACTIVE;
    });

  if (status.some(s => s === RelationStatus.ACTIVE)) {
    return RelationStatus.ACTIVE;
  }

  if (status.every(s => s === RelationStatus.UNKNOWN)) {
    return RelationStatus.UNKNOWN;
  }

  if (status.some(s => s === RelationStatus.INACTIVE)) {
    return RelationStatus.INACTIVE;
  }

  return RelationStatus.UNKNOWN;

}

function RelationStatusChip(props: { entity: AugmentedEntity }): ReactElement {
  const { t } = useTranslation();

  const status: RelationStatus = useMemo(() => generateStatus(props.entity), [props.entity]);

  return (
    <Stack direction='row' spacing={2} maxWidth={340} flexWrap='wrap' useFlexGap>
      <Chip
        key={status.toString()}
        color={RelationStatusToColor[status]}
        size='small'
        label={t(`common.status.${status}`)} />
    </Stack>
  );
}

export default RelationStatusChip;
