import React, { ReactElement, useRef } from 'react';
import { Outlet, useLoaderData, useLocation, useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { IconScreenshot } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import CustomTabs, { TabsComponentsProps } from '../layouts/tabs';
import { CustomRouteObject } from '@/router/types';
import captureScreenshot from '@/utils/screenshot';

export interface DataOutletProps extends Omit<TabsComponentsProps, 'tabs' | 'params'> {
  layout?: ReactElement,
  tabs?: CustomRouteObject[],
  childrens?: CustomRouteObject[],
  idChildrens?: CustomRouteObject[],
  wrapper?: (props: { children: ReactElement }) => React.JSX.Element
}

function DataOutlet(props: DataOutletProps): ReactElement {
  const data = useLoaderData();
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const ref = useRef(null);

  if (!props.layout && !props.tabs) {
    return <Outlet context={data} />;
  }

  return (
    <Stack spacing={2}>
      {props.wrapper ?
        <props.wrapper>
          <>
            {props.layout}
            <Stack spacing={2} ref={ref}>
              {props.tabs && !props.idChildrens?.find(idChildren => (idChildren.path && location.pathname.includes(idChildren.path.replaceAll(/\/:[a-zA-Z]+\?/g, '')))) && !props.childrens?.find(children => (children.path && location.pathname.includes(children.path.replaceAll(/\/:[a-zA-Z]+\?/g, '')))) &&
                <CustomTabs
                  tabs={props.tabs}
                  params={params}
                  actions={
                    <Tooltip title={t('common.utils.captureScreenshot')} placement='top' arrow>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          captureScreenshot(ref);
                        }}
                        disableTouchRipple
                        size='small'
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        <IconScreenshot size='1.2rem' />
                      </IconButton>
                    </Tooltip>
                  }
                  {...props}
                />
              }
              <Outlet context={data} />
            </Stack>
          </>
        </props.wrapper> :
        <>
          {props.layout}
          <Stack spacing={2} ref={ref}>
            {props.tabs && !props.idChildrens?.find(idChildren => (idChildren.path && location.pathname.includes(idChildren.path.replaceAll(/\/:[a-zA-Z]+\?/g, '')))) && !props.childrens?.find(children => (children.path && location.pathname.includes(children.path.replaceAll(/\/:[a-zA-Z]+\?/g, '')))) &&
            <CustomTabs
              tabs={props.tabs}
              params={params}
              actions={
                <Tooltip title={t('common.utils.captureScreenshot')} placement='top' arrow>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      captureScreenshot(ref);
                    }}
                    disableTouchRipple
                    size='small'
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    <IconScreenshot size='1.2rem' />
                  </IconButton>
                </Tooltip>
              }
              {...props}
            />
            }
            <Outlet context={data} />
          </Stack>
        </>
      }
    </Stack>
  );
}

export default DataOutlet;
