import React, { ReactElement, useEffect, useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import {
  AmfSanctionDataBlockV1,
  AmfTransactionDataBlockV1,
  BodaccDataBlock,
  IDataBlock
} from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import { uniqWith } from 'lodash';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import Timeline from '@/main/containers/bodacc/timeline';
import BodaccHeader from '../../../../../containers/bodacc/header';
import { FilterGroupChoices } from '@/main/containers/bodacc/types';
import { bodaccDataSort, getParsedData, getYearsBodacc } from '@/main/containers/bodacc/utils';
import BodaccFilters from '../../../../../containers/bodacc/filters';
import BodaccAccordions from '../../../../../containers/bodacc/accordions';

export const bodaccDataPaths = ['company.bodacc.publication', 'amf.sanction', 'amf.transaction'];

function BodaccTabsComponents(): ReactElement {
  const { t } = useTranslation();
  const data = useLoaderData() as { data: IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>[] };
  const [sortedData, setSortedData] = useState<IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>[]>([]);
  const [parsedData, setParsedData] = useState<IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>[]>([]);
  const [filters, setFilters] = useState<FilterGroupChoices<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>[]>([]);
  const [selectedId, setSelectedId] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCriticity, setSelectedCriticity] = useState(searchParams.get('criticity') || '');
  const [sanctionsAmfFilter, setSanctionsAmfFilter] = useState(true);

  useEffect(() => {
    setSortedData(uniqWith(data.data, (a, b) => (a.dataInfo.path === 'company.bodacc.publication' ? ((a.data as BodaccDataBlock).id === (b.data as BodaccDataBlock).id) : (a.data as AmfSanctionDataBlockV1).sanction.amfId === (b.data as AmfSanctionDataBlockV1).sanction.amfId)).sort(bodaccDataSort));
  }, [data]);

  useEffect(() => {
    setParsedData(getParsedData(sortedData.filter(d => (d.dataInfo.path === 'amf.sanction' ? sanctionsAmfFilter : true)), filters));
  }, [filters, sortedData, sanctionsAmfFilter]);

  useEffect(() => {
    if (selectedCriticity === '') {
      searchParams.delete('criticity');
    } else if (searchParams.get('criticity') !== selectedCriticity) {
      searchParams.set('criticity', selectedCriticity);
      setSearchParams(searchParams);
    }
  }, [selectedCriticity]);

  useEffect(() => {
    if (searchParams.get('criticity') !== selectedCriticity) {
      setSelectedCriticity(searchParams.get('criticity') || '');
    }
  }, [searchParams]);

  return (
    <Stack spacing={4}>
      {sortedData.length > 0 &&
        <>
          <BodaccHeader
            datas={sortedData}
            parsedDatas={parsedData}
            selectedCriticity={selectedCriticity}
            selectCriticity={
              (c: string) => setSelectedCriticity(prevState => (
                c === prevState ? '' : c
              ))
            }
          />
          <Stack direction='row' alignItems='center' width='100%'>
            <BodaccFilters
              datas={selectedCriticity !== '' ? sortedData.filter(d => (d.dataInfo.path === 'company.bodacc.publication' ? (d.data as BodaccDataBlock)._deecision?.criticity === selectedCriticity : selectedCriticity === 'alert')) : sortedData}
              parsedData={selectedCriticity !== '' ? parsedData.filter(d => (d.dataInfo.path === 'company.bodacc.publication' ? (d.data as BodaccDataBlock)._deecision?.criticity === selectedCriticity : selectedCriticity === 'alert')) : parsedData}
              setFilters={(f: FilterGroupChoices<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>[]) => setFilters(f)}
            />
            {sortedData.some(d => d.dataInfo.path === 'amf.sanction') ?
              <FormGroup aria-orientation='horizontal'>
                <FormControlLabel
                  key='label-AMF'
                  control={
                    <Checkbox
                      key={`AMF-${sanctionsAmfFilter}`}
                      checked={sanctionsAmfFilter}
                      onChange={() => setSanctionsAmfFilter(!sanctionsAmfFilter)}
                    />
                  }
                  label={t('bodacc.sanctions.amf')}
                />
              </FormGroup>
              : null
            }
          </Stack>
        </>
      }
      {(selectedCriticity !== '' ? parsedData.filter(d => (d.dataInfo.path === 'company.bodacc.publication' ? (d.data as BodaccDataBlock)._deecision?.criticity === selectedCriticity : selectedCriticity === 'alert')) : parsedData).length > 0 &&
        <>
          <Timeline
            datas={selectedCriticity !== '' ? parsedData.filter(d => (d.dataInfo.path === 'company.bodacc.publication' ? (d.data as BodaccDataBlock)._deecision?.criticity === selectedCriticity : selectedCriticity === 'alert')) : parsedData}
            years={getYearsBodacc(selectedCriticity !== '' ? parsedData.filter(d => (d.dataInfo.path === 'company.bodacc.publication' ? (d.data as BodaccDataBlock)._deecision?.criticity === selectedCriticity : selectedCriticity === 'alert')) : parsedData)}
            select={(id: string) => setSelectedId(id)}
          />
          <BodaccAccordions
            datas={selectedCriticity !== '' ? parsedData.filter(d => (d.dataInfo.path === 'company.bodacc.publication' ? (d.data as BodaccDataBlock)._deecision?.criticity === selectedCriticity : selectedCriticity === 'alert')) : parsedData}
            selectedId={selectedId}
          />
        </>
      }
    </Stack>
  );
}

export default BodaccTabsComponents;
