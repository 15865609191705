import {
  AmfSanctionDataBlockV1,
  AmfTransactionDataBlockV1,
  BodaccDataBlock,
  IDataBlock
} from '@deecision/dna-interfaces';
import { cloneDeep, get, sortBy, upperFirst } from 'lodash';
import { FilterGroupChoices } from './types';

export function bodaccDataSort( a: IDataBlock, b: IDataBlock ) {
  if (!a.dataInfo.dataTimestamp) {
    return -1;
  }
  if (!b.dataInfo.dataTimestamp) {
    return 1;
  }

  const d1 = new Date(a.dataInfo.dataTimestamp).getTime();
  const d2 = new Date(b.dataInfo.dataTimestamp).getTime();

  if (d1 > d2) {
    return -1;
  }
  if (d1 < d2) {
    return 1;
  }

  return 0;
}

export function getParsedData(data: IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>[], filters: FilterGroupChoices<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>[], index?: number, jIndex?: number): IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>[] {
  let dataTmp: IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>[] = cloneDeep(data);
  const filtersTmp: FilterGroupChoices<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>[] = cloneDeep(filters);

  if (filtersTmp.length > 0) {
    if (index !== undefined && jIndex === undefined) {
      if (filtersTmp[index].subFilters.every(subFilter => subFilter.status)) {
        filtersTmp[index].subFilters.forEach((tmpSubFilter, j) => {
          filtersTmp[index].subFilters[j].status = false;
        });
      } else {
        filtersTmp[index].subFilters.forEach((tmpSubFilter, j) => {
          filtersTmp[index].subFilters[j].status = true;
        });
      }
    } else if (index !== undefined && jIndex !== undefined) {
      filtersTmp[index].subFilters[jIndex].status = !filtersTmp[index].subFilters[jIndex].status;
    }

    filtersTmp.forEach((filter) => {
      if (filter.value === 'years') {
        filter.subFilters.forEach((subFilter) => {
          if (!subFilter.status) {
            dataTmp = dataTmp.filter(dTmps => !(new Date(get(dTmps, subFilter.key)).getFullYear().toString() === subFilter.value));
          }
        });
      } else if (filter.value === '') {
        filter.subFilters.forEach((subFilter) => {
          if (!subFilter.status) {
            dataTmp = dataTmp.filter(dTmps => !(upperFirst(get(dTmps.data, subFilter.key)) === subFilter.value));
          }
        });
      } else {
        filter.subFilters.forEach((subFilter) => {
          if (!subFilter.status) {
            dataTmp = dataTmp.filter(dTmps => !(upperFirst(get(dTmps.data, subFilter.key)).replaceAll('.', '') === subFilter.value));
          }
        });
      }
    });
  }

  return dataTmp;
}

export function getDateBodacc(data: IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>) {
  if (data.dataInfo.path === 'company.bodacc.publication' && data.dataInfo.dataTimestamp) {
    return new Date(data.dataInfo.dataTimestamp);
  }

  if (data.dataInfo.path === 'amf.transaction') {
    return new Date((data.data as AmfTransactionDataBlockV1).transaction.date);
  }

  if (data.dataInfo.path === 'amf.sanction') {
    return new Date((data.data as AmfSanctionDataBlockV1).sanction.date);
  }

  return undefined;
}

export function getIdBodacc(data: IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>) {
  if (data.dataInfo.path === 'company.bodacc.publication') {
    return (data.data as BodaccDataBlock).id;
  }

  if (data.dataInfo.path === 'amf.transaction') {
    return (data.data as AmfTransactionDataBlockV1).transaction.amfId;
  }

  if (data.dataInfo.path === 'amf.sanction') {
    return (data.data as AmfSanctionDataBlockV1).sanction.amfId;
  }

  return undefined;
}

export function getTooltipBodacc(data: IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>) {
  if (data.dataInfo.path === 'company.bodacc.publication') {
    return `Bodacc France - ${(data.data as BodaccDataBlock).familleavis_lib}`;
  }

  if (data.dataInfo.path === 'amf.transaction') {
    return `AMF (transaction) - ${(data.data as AmfTransactionDataBlockV1).transaction.theme}`;
  }

  if (data.dataInfo.path === 'amf.sanction') {
    return `AMF (sanction) - ${(data.data as AmfSanctionDataBlockV1).sanction.theme}`;
  }

  return undefined;
}

export function getCriticityBodacc(data: IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>) {
  if (data.dataInfo.path === 'company.bodacc.publication') {
    return (data.data as BodaccDataBlock)._deecision?.criticity;
  }

  if (data.dataInfo.path === 'amf.transaction' || data.dataInfo.path === 'amf.sanction') {
    return 'alert';
  }

  return undefined;
}

export function getPDFUrlBodacc(data: IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>) {
  if (data.dataInfo.path === 'company.bodacc.publication') {
    return (data.data as BodaccDataBlock)._deecision?.pdfUrl;
  }

  if (data.dataInfo.path === 'amf.transaction') {
    return (data.data as AmfTransactionDataBlockV1).transaction.downloadUrl;
  }

  if (data.dataInfo.path === 'amf.sanction') {
    return (data.data as AmfSanctionDataBlockV1).sanction.downloadUrl;
  }

  return undefined;
}

export function getWebsiteUrlBodacc(data: IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>) {
  if (data.dataInfo.path === 'company.bodacc.publication') {
    return (data.data as BodaccDataBlock)._deecision?.websiteUrl;
  }

  if (data.dataInfo.path === 'amf.transaction') {
    return (data.data as AmfTransactionDataBlockV1).transaction.originUrl;
  }

  if (data.dataInfo.path === 'amf.sanction') {
    return (data.data as AmfSanctionDataBlockV1).sanction.originUrl;
  }

  return undefined;
}

export function getTextBodacc(data: IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>) {
  if (data.dataInfo.path === 'company.bodacc.publication') {
    return '';
  }

  if (data.dataInfo.path === 'amf.transaction') {
    return (data.data as AmfTransactionDataBlockV1).transaction.text;
  }

  if (data.dataInfo.path === 'amf.sanction') {
    return (data.data as AmfSanctionDataBlockV1).sanction.text;
  }

  return undefined;
}

export function getTextMoreBodacc(data: IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>) {
  if (data.dataInfo.path === 'company.bodacc.publication') {
    return '';
  }

  if (data.dataInfo.path === 'amf.transaction') {
    return (data.data as AmfTransactionDataBlockV1).transaction.textActeur;
  }

  if (data.dataInfo.path === 'amf.sanction') {
    return (data.data as AmfSanctionDataBlockV1).sanction.textEgard;
  }

  return undefined;
}

export function getYearsBodacc(datas: IDataBlock<BodaccDataBlock | AmfSanctionDataBlockV1 | AmfTransactionDataBlockV1>[], sort?: 'asc' | 'dsc') {
  const yearsTmp: string[] = [];

  datas.forEach((d) => {
    if (getDateBodacc(d) && !yearsTmp.find(year => getDateBodacc(d) && year === getDateBodacc(d)?.getFullYear().toString())) {
      yearsTmp.push(getDateBodacc(d)?.getFullYear().toString() || new Date().getFullYear().toString());
    }
  });

  return (!sort || sort === 'asc') ? sortBy(yearsTmp).reverse() : sortBy(yearsTmp);
}
