import React, { useContext, useEffect, useState } from 'react';
import { useLoaderData, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Stack } from '@mui/material';
import { SegmentationFilter, SegmentationPossibleOutputEntityTypes } from '@deecision/dna-interfaces';
import { cloneDeep, lowerCase } from 'lodash';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import { SegmentationCriteriaSpec } from '@deecision/dna-interfaces/dist/segmentation/segmentationFilters';
import { VennDiagramConfig, VennDiagramData, VennDiagramResult } from '../types';
import VennDiagramServices from '../services';
import CustomAccordion from '../../../../components/accordion';
import VennDiagramChart from './charts';
import {
  BaseCustomUserData,
  BaseSegmentationProps,
  CustomSegmentationData
} from '../../segmentations/types';
import getDynamicGroupsRequest from '../../../modules/deetect/portfolios/utils/dynamicgroups';
import { SegmentationContext } from '../../segmentations/wrapper';
import { possibleCriteriaTypes } from '../../segmentations/builder/workflows';
import RenderSegmentations from '../../segmentations/render';
import VennDiagramTitle from '@/main/containers/vennDiagram/render/title';
import ConfigVennDiagram from '@/main/containers/vennDiagram/builder/config';
import { VennDiagramContext } from '@/main/containers/vennDiagram/wrapper';

function RenderVennDiagram() {
  const { t } = useTranslation();
  const theme = useTheme();
  const diagramContext = useContext(VennDiagramContext);
  const segmentationContext = useContext(SegmentationContext);
  const { vennDiagramId, id } = useParams();
  const [searchParams] = useSearchParams();
  const [result, setResult] = useState<VennDiagramResult[]>();
  const [filters, setFilters] = useState<BaseSegmentationProps['filters']>([]);
  const vennServices = new VennDiagramServices<VennDiagramConfig>();
  const userData = useLoaderData() as { data?: BaseCustomUserData };
  const dataParams = userData?.data? cloneDeep(userData?.data) : undefined;
  const entityType = searchParams.get('entityType');
  const groupId = searchParams.get('groupId');
  const customGroupId = searchParams.get('customGroupId');
  const filterIds = searchParams.get('filterIds')?.split(',');
  const filterValues = searchParams.get('filterValues')?.split(',');
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(vennDiagramId === 'create');

  const dataSetId = (lowerCase(entityType || '')?.includes('person') || dataParams?.data.on?.includes('person') || diagramContext?.vennDiagram?.objectType?.includes('Person'))
    ? 'deecPersons'
    : 'deecCompanies';

  const baseFilterGroupMember: SegmentationFilter[] = id ? (customGroupId === 'true' && groupId) ? getDynamicGroupsRequest(groupId, id)?.filters || [] : [
    {
      id: dataSetId === 'deecPersons' ? 'person_groupMember' : 'company_groupMember',
      filterId: dataSetId === 'deecPersons' ? 'person_groupMember' : 'company_groupMember',
      type: 'filter',
      on: dataSetId === 'deecPersons' ? 'person1' : 'company',
      values: groupId && groupId.split(',').length > 1 ? groupId.split(',').map(uniqGroupId => `${id}/${uniqGroupId}`) : [`${id}/${groupId || (dataSetId === 'deecPersons' ? 'persons' : 'companies')}`]
    }
  ] : [];

  const baseFiltersFromUrlParams: SegmentationFilter[] = filterIds && filterValues ? filterIds.map((filterId, index) => ({
    id: filterId,
    filterId,
    type: 'filter',
    on: dataSetId === 'deecPersons' ? 'person1' : 'company',
    values: [filterValues[index] === 'true' ? true : filterValues[index] === 'false' ? false : filterValues[index]]
  })) : [];

  const getCriterias = (outputEntitiesTmp?: SegmentationPossibleOutputEntityTypes) => segmentationContext?.segmentation?.data.potentialSegmentationCriterias.filter(criteria => possibleCriteriaTypes(undefined, outputEntitiesTmp ? [outputEntitiesTmp] : undefined).includes(criteria.on)) || [];

  const makeSegmentationCriterias = (criterias: CustomSegmentationData['potentialSegmentationCriterias']) => criterias.map(criteria => ({
    ...criteria,
    type: 'segmentationCriteria',
    on: (criteria.on === 'person' ? `person${(diagramContext?.vennDiagram?.objectType === 'deecPerson' ? 'person1' : 'company') === 'person1' ? '1' : '2'}` : criteria.on === 'company' ? `company${(diagramContext?.vennDiagram?.objectType === 'deecPerson' ? 'person1' : 'company') === 'person1' ? '1' : '2'}` : criteria.on)
  })).filter(criteria => !filters.find(filter => filter.filterId === criteria.filterId));

  const addFilter = (filter: typeof filters[0]) => {
    setFilters([filter]);
  };

  const removeFilter = () => {
    setFilters([]);
  };

  const changeIsEditMode = (isEditModeTmp: boolean) => {
    setIsEditMode(isEditModeTmp);
    if (isEditModeTmp) {
      setIsDraft(true);
    }
  };

  const changeCustomDiagram = (diagramTmp: VennDiagramConfig | undefined) => {
    setIsDraft(true);
    diagramContext?.setVennDiagram(diagramTmp);
  };

  const getVennDiagramResults = () => {
    if (diagramContext?.vennDiagram) {
      const outputEntities = diagramContext.vennDiagram.objectType === 'deecPerson' ? 'person1' : 'company';

      const contextFromParams = {
        context: {
          dataSetId,
          outputEntities: dataSetId === 'deecPersons' ? 'person1' : 'company',
          filters: [
            ...baseFilterGroupMember,
            ...baseFiltersFromUrlParams
          ],
          globalFilteringItems: [],
          entitiesSettings: {
            includeEntities: false,
            findOptions: {
              pagingParams: {},
              queryFilters: [],
              sortOptions: []
            }
          },
          segmentationCriterias: makeSegmentationCriterias(getCriterias(outputEntities)) as SegmentationCriteriaSpec[]
        }
      };

      if (vennDiagramId) {
        if (isDraft) {
          vennServices.executeCustom<VennDiagramData>(diagramContext.vennDiagram, contextFromParams)
            .then((res) => {
              if (res.data) {
                setResult(res.data.result);
              }
            });
        } else if (vennDiagramId !== 'create') {
          vennServices.execute<VennDiagramData>(vennDiagramId, contextFromParams)
            .then((res) => {
              if (res.data) {
                setResult(res.data.result);
              }
            });
        }
      }
    }
  };

  useEffect(() => {
    getVennDiagramResults();
  }, [diagramContext?.vennDiagram]);

  return (
    <>
      {diagramContext?.vennDiagram ?
        <Grid container spacing={4}>
          {/* Header */}
          <VennDiagramTitle
            isDraft={isDraft}
            setIsDraft={setIsDraft}
            isEditMode={isEditMode}
            setIsEditMode={changeIsEditMode}
            setDiagram={setResult}
          />
          <Grid size={12}>
            <RenderSegmentations
              filters={filters}
              removeFilter={removeFilter}
              reset={() => setFilters([])}
            >
              {isEditMode ?
                <ConfigVennDiagram
                  data={diagramContext.vennDiagram}
                  setData={changeCustomDiagram}
                  access={diagramContext.vennDiagram.ownerType || 'me'}
                  setAccess={diagramContext.updateAccess}
                />
                : null
              }
              <CustomAccordion
                title={t('segmentation.vennDiagram.label')}
                bgcolor={theme.palette.background.default}
                defaultOpen
              >
                <Stack
                  id='venn-diagram'
                  display='flex'
                  height='min-content'
                  width='100%'
                  justifyContent='center'
                >
                  <VennDiagramChart
                    key={JSON.stringify(result)}
                    diagram={diagramContext.vennDiagram}
                    result={result || []}
                    filters={filters}
                    setFilters={setFilters}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    setData={changeCustomDiagram}
                    updateDiagram={getVennDiagramResults}
                  />
                </Stack>
              </CustomAccordion>
            </RenderSegmentations>
          </Grid>
        </Grid>
        :
        <Stack height='100%' alignContent='center' alignItems='center'>
          <CircularProgress/>
        </Stack>
      }
    </>
  );
}

export default RenderVennDiagram;
