import React, { ReactElement, useEffect, useState } from 'react';
import { CompanyEntity, FinancialReportDataBlock, IDataBlock } from '@deecision/dna-interfaces';
import { uniq, upperFirst } from 'lodash';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { WidgetProps } from '@/main/containers/widgets/types';
import { DataBlocksQueryService } from '@/api/services/query';
import FinancialChart, { baseSelectedKeys } from '@/main/containers/financial/chart';
import { getPathList, parseAndSortFinancialData } from '@/main/containers/financial/utils';

function CompanyWidgetsFinancialChart(props: WidgetProps<CompanyEntity>): ReactElement {
  const [selectedKey, setSelectedKey] = useState(baseSelectedKeys);
  const dataElementQueryService = new DataBlocksQueryService<FinancialReportDataBlock>({ dataPath: 'company.financialReportDetails', entityType: 'deecCompany' });
  const [dataBlocks, setDataBlocks] = useState<IDataBlock<FinancialReportDataBlock>[]>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [selectedBalanceSheetType, setSelectedBalanceSheetType] = useState<string>();

  const getDataBlock = () => {
    dataElementQueryService.get(props.data.entityId)
      .then((res) => {
        if (res.data) {
          const optionsTmp = uniq(res.data.map(dataBlock => dataBlock.data.balanceSheetType));

          setDataBlocks(parseAndSortFinancialData(res.data));
          setOptions(optionsTmp);
          setSelectedBalanceSheetType(optionsTmp.includes('consolidated') ? 'consolidated' : optionsTmp.includes('full') ? 'full' : optionsTmp[0]);
        }
      });
  };

  useEffect(() => {
    getDataBlock();
  }, [props.data]);

  return (
    <FinancialChart
      data={dataBlocks.filter(dataBlock => dataBlock.data.balanceSheetType === selectedBalanceSheetType)}
      pathList={getPathList(dataBlocks.filter(dataBlock => dataBlock.data.balanceSheetType === selectedBalanceSheetType))}
      selectedKey={selectedKey}
      setSelectedKey={setSelectedKey}
      actions={
        options.length > 1 ?
          <Select
            native
            variant='outlined'
            value={selectedBalanceSheetType}
            onChange={event => setSelectedBalanceSheetType(event.target.value)}
            size='small'
            sx={{
              ml: 'auto !important'
            }}
          >
            {options.map(option => (
              <option key={option} value={option}>
                {upperFirst(option)}
              </option>
            ))}
          </Select> :
          options[0] ? <Typography>
            {upperFirst(options[0])}
          </Typography> : null
      }
    />
  );
}

export default CompanyWidgetsFinancialChart;
