import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { IDataBlock, FinancialReportDataBlock } from '@deecision/dna-interfaces';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import { uniq, upperFirst } from 'lodash';
import Typography from '@mui/material/Typography';
import FinancialGrid from '@/main/containers/financial/grid';
import FinancialYearList from '@/main/containers/financial/list';
import Selector from '@/components/selector';
import { getPathList, parseAndSortFinancialData } from '@/main/containers/financial/utils';
import FinancialChart, { baseSelectedKeys } from '@/main/containers/financial/chart';

function Financial(props: { data: IDataBlock<FinancialReportDataBlock>[] }): ReactElement {
  const [selectedKey, setSelectedKey] = useState(baseSelectedKeys);
  const [view, setView] = useState('grid');
  const dataSortedAndParsed = useMemo(() => parseAndSortFinancialData(props.data), [props.data]);
  const [options, setOptions] = useState<string[]>([]);
  const [selectedBalanceSheetType, setSelectedBalanceSheetType] = useState<string>();
  const pathList = useMemo(() => getPathList(dataSortedAndParsed.filter(dataBlock => dataBlock.data.balanceSheetType === selectedBalanceSheetType)), [dataSortedAndParsed, selectedBalanceSheetType]);

  useEffect(() => {
    const optionsTmp = uniq(dataSortedAndParsed.map(dataBlock => dataBlock.data.balanceSheetType));

    setSelectedBalanceSheetType(optionsTmp.includes('consolidated') ? 'consolidated' : optionsTmp.includes('full') ? 'full' : optionsTmp[0]);
    setOptions(optionsTmp);
  }, [dataSortedAndParsed]);

  return (
    <Grid container spacing={4}>
      <Grid size='grow'>
        <FinancialChart
          data={dataSortedAndParsed.filter(dataBlock => dataBlock.data.balanceSheetType === selectedBalanceSheetType)}
          selectedKey={selectedKey}
          setSelectedKey={setSelectedKey}
          pathList={pathList}
        />
      </Grid>
      <Grid size={12}>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
          <Selector
            options={[{ label: 'Grid', value: 'grid' }, { label: 'Per Year', value: 'perYear' }]}
            option={view}
            setOption={option => setView(option)}
          />
          {options.length > 1 ?
            <Select
              native
              variant='outlined'
              value={selectedBalanceSheetType}
              onChange={event => setSelectedBalanceSheetType(event.target.value)}
              size='small'
              sx={{
                width: 180
              }}
            >
              {options.map(option => (
                <option key={option} value={option}>
                  {upperFirst(option)}
                </option>
              ))}
            </Select> :
            options[0] ? <Typography>
              {upperFirst(options[0])}
            </Typography> : null
          }
        </Stack>
      </Grid>
      <Grid size={12}>
        {{
          perYear: <FinancialYearList datas={dataSortedAndParsed.filter(dataBlock => dataBlock.data.balanceSheetType === selectedBalanceSheetType)} selectedKey={selectedKey} setSelectedKey={setSelectedKey}/>,
          grid: <FinancialGrid datas={dataSortedAndParsed.filter(dataBlock => dataBlock.data.balanceSheetType === selectedBalanceSheetType)} selectedKey={selectedKey} setSelectedKey={setSelectedKey} pathList={pathList} />
        }[view]}
      </Grid>
    </Grid>
  );
}

export default Financial;
