import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import {
  SegmentationCriteriaInfo,
  SegmentationPossibleDisplayTypes
} from '@deecision/dna-interfaces';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { darken, useTheme } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {
  IconArrowsSplit2, IconBuildings,
  IconChartBar,
  IconChartDonut,
  IconChartPpf,
  IconDotsVertical, IconScreenshot,
  IconTrash, IconUsers
} from '@tabler/icons-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { lowerFirst } from 'lodash';
import { SegmentationData } from '@deecision/dna-interfaces/dist/segmentation/segmentation';
import Chip from '@mui/material/Chip';
import { BaseChartsProps } from '@/components/charts/types';
import PieCharts from '../../../../../components/charts/pie';
import YesNoCharts from '../../../../../components/charts/yesno';
import BarCharts from '../../../../../components/charts/bar';
import {
  BaseSegmentationProps,
  PotentialSegmentationCriteria
} from '../../types';
import { baseChartHeight, baseChartWidth } from '@/components/charts/utils';
import ClassificationChip from '../../components/classification.chips';
import captureScreenshot from '@/utils/screenshot';

export interface ChartDispatchSegmentationsProps extends BaseSegmentationProps {
  criteria: PotentialSegmentationCriteria & SegmentationData['segmentationCriterias'][0],
  handleDelete?: (segmentationCriteria:  PotentialSegmentationCriteria & SegmentationData['segmentationCriterias'][0]) => void,
  handleChangeDisplayType?: (displayType: SegmentationPossibleDisplayTypes, segmentationCriteria: PotentialSegmentationCriteria & SegmentationData['segmentationCriterias'][0]) => void,
  totalCount: number
}

function DispatchChartsSegmentations(props: ChartDispatchSegmentationsProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selected, setSelected] = useState<number>();
  const [halfPieChart, setHalfPieChart] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(true);
  const ref = useRef(null);

  const criteria = useMemo(() => (
    props.criteria
  ), [props.criteria.values, props.criteria.on, props.criteria.displayInfo.displayType]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setValuesOrRanges = () => {
    const value = criteria.values?.[selected || 0]?.value ?? undefined;
    const range = criteria.values?.[selected || 0]?.range ?? undefined;

    props.addFilter({
      id: criteria.id || 'unknown',
      filterId: (criteria?.filterSpec ? undefined : criteria.filterId) || 'unknown',
      label: criteria.displayInfo.label || criteria.filterLabel,
      on: (criteria?.filterSpec ? criteria.filterSpec.on : criteria?.on) || 'person1',
      valueType: criteria.filterId.includes('asset_complexity_reasons')
        ? 'asset_complexity_reasons'
        : criteria.filterId.includes('tag')
          ? 'tag'
          : criteria.displayInfo.valueType || criteria?.valueType || 'string' as const,
      type: criteria?.filterSpec ? criteria.filterSpec.type : 'filter' as const,
      subItemSpecs: criteria?.filterSpec ? criteria.filterSpec.subItemSpecs : undefined,
      values: value !== undefined ? [value] : undefined,
      ranges: range !== undefined ? [range] : undefined
    } as typeof props.filters[0]);
    setSelected(undefined);
  };

  const Charts: Record<SegmentationCriteriaInfo['displayInfo']['displayType'], (props: BaseChartsProps) => ReactElement> = {
    pieChart: elementProps => PieCharts({ ...elementProps, halfPieChart }),
    yesNo: elementProps => YesNoCharts(elementProps),
    barChart: elementProps => BarCharts({ ...elementProps })
  };

  const ChartComponent = useMemo(() => {
    const Chart = Charts[props.criteria.displayInfo.displayType];

    return (
      <Chart
        {...props}
        datas={[criteria?.values || []]}
        on={criteria.on === 'person' ? t('entities.persons.label') : criteria.on === 'company' ? t('entities.companies.label') : criteria.on === 'person2person' ? t('entities.links.multiple') : t('common.utils.unknown')}
        selected={selected}
        dataType={criteria.filterId.includes('asset_complexity_reasons')
          ? 'asset_complexity_reasons'
          : criteria.filterId.includes('tag')
            ? 'tag'
            : criteria?.valueType
        }
        setSelected={setSelected}
        loading={loading}
      />
    );
  }, [props.criteria.filterId, selected, loading, props.criteria.displayInfo.displayType, criteria?.values, criteria?.valueType]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [props.criteria.displayInfo.displayType, criteria?.values, criteria?.valueType]);

  return (
    <Paper
      sx={{
        width: '100%',
        bgcolor: theme.palette.background.paper
      }}
      ref={ref}
    >
      <Stack spacing={2}>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
          <Stack direction='row' spacing={2} alignItems='center' width='100%' overflow='auto' sx={{ overflowY: 'hidden' }}>
            <Box width={24} height={24}>
              {criteria.displayInfo.displayType === 'barChart' && (halfPieChart ? <IconChartPpf /> : <IconChartBar />)}
              {criteria.displayInfo.displayType === 'pieChart' && <IconChartDonut />}
              {criteria.displayInfo.displayType === 'yesNo' && <IconArrowsSplit2 />}
            </Box>
            <Typography variant='h5' p={1} noWrap width='max-content' overflow='visible' textOverflow='unset'>{criteria.displayInfo.label || criteria.filterLabel}</Typography>
            <Chip
              label={criteria.on === 'person' ? <IconUsers size='1rem' /> : <IconBuildings size='1rem' />}
              color={criteria.on === 'person' ? 'primary' : criteria.on === 'company' ? undefined : 'warning'}
              sx={criteria.on === 'company' ? {
                bgcolor: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                pt: 1
              } : {
                pt: 1
              }}
              size='small'
            />
            {criteria.classifications?.map(label =>
              <ClassificationChip label={label} />
            )}
          </Stack>
          <IconButton
            size='small'
            id='chart-menu-button'
            onClick={handleClick}
            aria-controls={open ? 'chart-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <IconDotsVertical size='1.2rem' />
          </IconButton>
          <Menu
            id='chart-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'chart-menu-button'
            }}
          >
            <MenuItem onClick={() => {
              captureScreenshot(ref);
              handleClose();
            }} sx={{ p: 2 }}>
              <Stack direction='row' spacing={2} alignItems='center'>
                <IconScreenshot size='1.4rem' />
                <Typography>
                  {t('common.utils.captureScreenshot')}
                </Typography>
              </Stack>
            </MenuItem>
            {criteria?.displayInfo?.displayType !== 'barChart' && criteria?.possibleDisplayTypes?.includes('barChart') &&
              <MenuItem
                onClick={() => {
                  props.handleChangeDisplayType?.('barChart', criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconChartBar color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.barChart') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {(criteria?.displayInfo?.displayType !== 'pieChart' || halfPieChart) && criteria?.possibleDisplayTypes?.includes('pieChart') &&
              <MenuItem
                onClick={() => {
                  setHalfPieChart(false);
                  props.handleChangeDisplayType?.('pieChart', criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconChartDonut color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.pieChart') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {(criteria?.displayInfo?.displayType !== 'pieChart' || !halfPieChart) && criteria?.possibleDisplayTypes?.includes('pieChart') &&
              <MenuItem
                onClick={() => {
                  setHalfPieChart(true);
                  props.handleChangeDisplayType?.('pieChart', criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconChartPpf color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.halfPieChart') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {criteria?.displayInfo?.displayType !== 'yesNo' && criteria?.possibleDisplayTypes?.includes('yesNo') &&
              <MenuItem
                onClick={() => {
                  props.handleChangeDisplayType?.('yesNo', criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconArrowsSplit2 color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.yesNo') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {props.handleDelete &&
              <MenuItem
                onClick={() => {
                  props.handleDelete?.(criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconTrash color={theme.palette.error.main} />
                </ListItemIcon>
                <ListItemText sx={{ pr: 4, color: theme.palette.error.main }}>
                  {t('segmentation.builder.custom.delete')}
                </ListItemText>
              </MenuItem>
            }
          </Menu>
        </Stack>
        <Box
          sx={{
            height: baseChartHeight,
            display: 'flex',
            justifyContent: 'center',
            position: 'relative'
          }}
        >
          {criteria.values
            ? criteria.values.length > 0
              ? ChartComponent
              : <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='subtitle1'>
                  {t('common.utils.noData')}
                </Typography>
              </Stack>
            : <Stack direction='row' spacing={1} alignItems='center'>
              {criteria.displayInfo.displayType === 'pieChart' ? [
                <Skeleton variant='circular' width={baseChartHeight / 2} height={baseChartHeight / 2} />,
                <Stack spacing={2} pl={8}>
                  {[...Array(7)].map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Skeleton key={index} variant='rounded' width={96} height={16} />
                  ))}
                </Stack>
              ] :
                [...Array(7)].map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Skeleton key={index} variant='rectangular' width={baseChartWidth / 9} height='75%' sx={{ borderRadius: '8px' }} />
                ))
              }
            </Stack>
          }
          <Collapse
            in={selected !== undefined}
            sx={{
              bottom: 0,
              bgcolor: theme.palette.background.default,
              borderRadius: 2,
              borderBottomRightRadius: '8px',
              borderBottomLeftRadius: '8px',
              border: `1px solid ${theme.palette.divider}`,
              zIndex: 2,
              position: 'absolute',
              width: '100%'
            }}
          >
            <Grid container spacing={2} p={2} width='100%'>
              <Grid size='grow'>
                <Button
                  onClick={() => setSelected(undefined)}
                  sx={{
                    '&:hover': {
                      color: theme.palette.error.main
                    }
                  }}
                  fullWidth
                >
                  {t('segmentation.filters.cancel')}
                </Button>
              </Grid>
              <Grid size='grow'>
                <Button
                  variant='contained'
                  onClick={setValuesOrRanges}
                  sx={{
                    bgcolor: theme.palette.success.main,
                    borderColor: theme.palette.success.dark,
                    '&:hover': {
                      bgcolor: darken(theme.palette.success.main, 0.3),
                      borderColor: theme.palette.success.main
                    }
                  }}
                  fullWidth
                >
                  {t('segmentation.filters.apply')}
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      </Stack>
    </Paper>
  );
}

export default DispatchChartsSegmentations;
