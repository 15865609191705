import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  AmfSanctionDataBlockV1,
  AmfTransactionDataBlockV1,
  BodaccDataBlock,
  IDataBlock
} from '@deecision/dna-interfaces';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { upperFirst } from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { IconAlertTriangleFilled, IconDownload, IconExternalLink } from '@tabler/icons-react';
import Divider from '@mui/material/Divider';
import { currencyFormatter } from '@/utils';
import { BodaccElementsProps, Criticity } from './types';
import KeyValueList from '@/components/keyvalue/keyvaluelist';
import {
  getCriticityBodacc,
  getDateBodacc,
  getIdBodacc,
  getPDFUrlBodacc, getTextBodacc, getTextMoreBodacc, getTooltipBodacc,
  getWebsiteUrlBodacc
} from '@/main/containers/bodacc/utils';

function BodaccEvent(props: Pick<BodaccElementsProps, 'selectedId'> & { data: IDataBlock<BodaccDataBlock>, criticity: Criticity, yearOpen: boolean }): ReactElement {
  const { data } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [descOpen, setDescOpen] = useState(false);
  const [selected, setSelected] = useState(false);

  const handleDownload = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    window.open(getPDFUrlBodacc(props.data) || '', '_blank');
  };

  const handleOpenLink = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    window.open(getWebsiteUrlBodacc(props.data) || '', '_blank');
  };

  useEffect(() => {
    if (props.selectedId === getIdBodacc(props.data)) {
      setDescOpen(true);
      setSelected(true);
      setTimeout(()=>{
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    } else if (selected) {
      setSelected(false);
    }
  }, [props.selectedId]);

  useEffect(() => {
    if (!descOpen && selected) {
      setSelected(false);
    }
  }, [descOpen]);

  useEffect(() => {
    if (!props.yearOpen) {
      setSelected(false);
      setDescOpen(false);
    }
  }, [props.yearOpen]);

  return (
    <Paper
      ref={ref}
      variant='hoverElevation1'
      onClick={() => setDescOpen(prevState => !prevState)}
      sx={{
        p: 1,
        pl: 2,
        width: 'calc(100% - 32px)',
        border: 'solid 1px',
        borderColor: selected ? theme.primaryColors?.light : 'grey.500',
        bgcolor: selected ? theme.primaryColors?.light : 'grey.50',
        transition: 'background-color 200ms, border-color 200ms',
        '&:hover': {
          cursor: 'pointer',
          borderColor: selected ? theme.primaryColors?.light : 'grey.200',
          bgcolor: selected ? theme.primaryColors?.light :'grey.50',
          transition: 'background-color 200ms, border-color 200ms'
        }
      }}
    >
      <Stack spacing={2} width='100%'>
        <Stack direction='row' spacing={2} alignItems='center' height='28px' p={4} pl={2} pr={1}>
          <Box
            sx={{
              width: '14px',
              height: '14px',
              borderRadius: 2,
              mr: 1,
              backgroundColor: getCriticityBodacc(props.data) ? props.criticity[getCriticityBodacc(props.data) || 'alert'].main : 'grey.500'
            }}
          />
          <Typography>
            {`${data.data.typeavis ? upperFirst(data.data.typeavis) : ''} Bodacc France n°${data.data.numeroannonce || ''}`}
          </Typography>
          <Typography>
            -
          </Typography>
          <Typography>
            {data.data.familleavis_lib}
          </Typography>
          <Typography variant='body2'>
            ({data.data._deecision?.json?.depot?.typeDepot || data.data._deecision?.json?.modificationsgenerales?.descriptif || data.data.familleavis_lib || ''})
          </Typography>
          <Stack direction='row' spacing={0} alignItems='center' height='28px' ml='auto !important' pt='1px'>
            <Tooltip title={t('common.utils.openLink')} arrow placement='top'>
              <Button
                variant='icon'
                size='small'
                onClick={handleOpenLink}
                sx={{
                  mr: '-4px',
                  transform: 'scale(0.8)'
                }}
              >
                <IconExternalLink size='1.4rem' />
              </Button>
            </Tooltip>
            {!data.data._deecision?.isPdfUnitaire &&
              <Tooltip title={t('warning.bigFile')} placement='top' arrow>
                <IconAlertTriangleFilled style={{ marginLeft: '16px', color: theme.palette.warning.main }} />
              </Tooltip>
            }
            <Tooltip title={t('common.utils.downloadFile')} arrow placement='top'>
              <Button
                variant='icon'
                size='small'
                onClick={handleDownload}
                sx={{
                  mr: '-4px',
                  transform: 'scale(0.8)'
                }}
              >
                <IconDownload size='1.4rem' />
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
        {descOpen &&
          <>
            <Divider />
            <Box width='100%' sx={{ overflowX: 'auto' }} p={2}>
              <Stack spacing={2}>
                <Box sx={{ width: 'max-content' }}>
                  <KeyValueList
                    values={[[
                      {
                        key: 'Publié le',
                        value: data.data.dateparution ? new Date(data.data.dateparution).toLocaleString() : ''
                      },
                      {
                        key: 'Annonce déposée au',
                        value: data.data.tribunal || ''
                      },
                      {
                        key: 'Référence de publication',
                        value: `${data.data.publicationavis_facette || ''} n°${data.data.parution || ''} du ${data.data.dateparution || ''}, annonce n°${data.data.numeroannonce || ''}`
                      },
                      {
                        key: 'Nature',
                        value: data.data._deecision?.json?.jugement?.nature || ''
                      },
                      {
                        key: 'N° RCS',
                        value: `${data.data._deecision?.json?.listepersonnes?.personne?.numeroImmatriculation?.numeroIdentification || ''} ${data.data._deecision?.json?.listepersonnes?.personne?.numeroImmatriculation?.codeRCS || ''} ${data.data._deecision?.json?.listepersonnes?.personne?.numeroImmatriculation?.nomGreffeImmat || ''}`
                      },
                      {
                        key: 'Dénomination',
                        value: data.data._deecision?.json?.listepersonnes?.personne?.denomination || ''
                      },
                      {
                        key: 'Forme juridique',
                        value: data.data._deecision?.json?.listepersonnes?.personne?.formeJuridique || ''
                      },
                      {
                        key: 'Adresse du siège social',
                        value: `${data.data._deecision?.json?.listepersonnes?.personne?.adresseSiegeSocial?.numeroVoie || ''} ${data.data._deecision?.json?.listepersonnes?.personne?.adresseSiegeSocial?.typeVoie || ''} ${data.data._deecision?.json?.listepersonnes?.personne?.adresseSiegeSocial?.nomVoie || ''} ${data.data._deecision?.json?.listepersonnes?.personne?.adresseSiegeSocial?.codePostal || data.data.cp || ''} ${data.data._deecision?.json?.listepersonnes?.personne?.adresseSiegeSocial?.ville || data.data.ville || ''}`
                      },
                      {
                        key: 'Comptes consolidés et rapports de l\'exercice clos le',
                        value: data.data._deecision?.json?.depot?.dateCloture || ''
                      },
                      {
                        key: 'Capital',
                        value: data.data._deecision?.json?.listepersonnes?.personne?.capital && currencyFormatter({ currency: data.data._deecision?.json?.listepersonnes?.personne?.capital.devise, value: parseInt(data.data._deecision?.json?.listepersonnes?.personne?.capital.montantCapital || '', 10) }) || ''
                      },
                      {
                        key: 'Date de jugement',
                        value: data.data._deecision?.json?.jugement?.date || ''
                      }
                    ]]}
                  />
                </Box>
                {data.data._deecision?.json?.jugement?.complementJugement &&
                  <>
                    <Stack direction='row' spacing={6}>
                      <Typography
                        variant='body1'
                        fontWeight={400}
                        color={theme.palette.text.secondary}
                        ml='-3px !important'
                        mr='3px !important'
                      >
                        Jugement
                      </Typography>
                      <Typography
                        variant='h5'
                        sx={{
                          display: 'block',
                          whiteSpace: 'pre-line',
                          width: 'max-content',
                          fontWeight: 400
                        }}
                      >
                        {data.data._deecision?.json?.jugement?.complementJugement || ''}
                      </Typography>
                    </Stack>
                  </>
                }
                {data.data._deecision?.json?.listepersonnes?.personne?.administration &&
                  <>
                    <Stack direction='row' spacing={6}>
                      <Typography
                        variant='body1'
                        fontWeight={400}
                        color={theme.palette.text.secondary}
                        ml='-3px !important'
                        mr='3px !important'
                      >
                        Administration
                      </Typography>
                      <Typography
                        variant='h5'
                        sx={{
                          display: 'block',
                          whiteSpace: 'pre-line',
                          width: 'max-content',
                          fontWeight: 400
                        }}
                      >
                        {(data.data._deecision?.json?.listepersonnes?.personne?.administration)?.replaceAll(', Administrateur', '\n Administrateur').replaceAll(';', '\n') || ''}
                      </Typography>
                    </Stack>
                  </>
                }
                {data.data._deecision?.json?.listeetablissements?.etablissement &&
                  <>
                    <Divider sx={{ mt: '8px !important', mb: '8px !important' }} />
                    <Typography variant='h5' mb='8px !important'>Établissement(s)</Typography>
                    <Box sx={{ width: 'max-content' }}>
                      <KeyValueList
                        values={[[
                          {
                            key: 'Origine du fond',
                            value: `${data.data._deecision?.json.listeetablissements.etablissement.origineFonds || ''}`
                          },
                          {
                            key: 'Activité',
                            value: `${data.data._deecision?.json.listeetablissements.etablissement.activite || ''}`
                          },
                          {
                            key: 'Adresse de l\'établissement',
                            value: `${data.data._deecision?.json.listeetablissements.etablissement.adresse?.numeroVoie || ''} ${data.data._deecision?.json.listeetablissements.etablissement.adresse?.typeVoie || ''} ${data.data._deecision?.json.listeetablissements.etablissement.adresse?.nomVoie || ''} ${data.data._deecision?.json.listeetablissements.etablissement.adresse?.codePostal || ''} ${data.data._deecision?.json.listeetablissements.etablissement.adresse?.ville || ''}`
                          }
                        ]]}
                      />
                    </Box>
                  </>
                }
                {data.data._deecision?.json?.listeprecedentproprietaire?.personne &&
                  <>
                    <Divider sx={{ mt: '8px !important', mb: '8px !important' }} />
                    <Typography variant='h5' mb='8px !important'>Précédent(s) Propriétaire(s)</Typography>
                    <Box sx={{ width: 'max-content' }}>
                      <KeyValueList
                        values={[[
                          {
                            key: 'N° RCS',
                            value: `${data.data._deecision?.json?.listeprecedentproprietaire?.personne?.numeroImmatriculation?.numeroIdentification || ''} ${data.data._deecision?.json?.listeprecedentproprietaire?.personne?.numeroImmatriculation?.codeRCS || ''} ${data.data._deecision?.json?.listeprecedentproprietaire?.personne?.numeroImmatriculation?.nomGreffeImmat || ''}`
                          },
                          {
                            key: 'Dénomination',
                            value: data.data._deecision?.json?.listeprecedentproprietaire?.personne?.denomination || ''
                          }
                        ]]}
                      />
                    </Box>
                  </>
                }
                {data.data._deecision?.json?.acte &&
                  <>
                    <Divider sx={{ mt: '8px !important', mb: '8px !important' }} />
                    <Box sx={{ width: 'max-content' }}>
                      <KeyValueList
                        values={[[
                          {
                            key: 'Publication légale',
                            value: `${data.data._deecision?.json?.acte.vente?.publiciteLegale?.titre || ''} du ${data.data._deecision?.json?.acte.vente?.publiciteLegale?.date || ''}`
                          },
                          {
                            key: 'Oppositions',
                            value: data.data._deecision?.json?.acte.vente?.opposition || ''
                          },
                          {
                            key: 'Commentaires',
                            value: data.data._deecision?.json?.acte.descriptif || ''
                          }
                        ]]}
                      />
                    </Box>
                  </>
                }
              </Stack>
            </Box>
          </>
        }
      </Stack>
    </Paper>
  );
}
function BodaccAMFEvent(props: Pick<BodaccElementsProps, 'selectedId'> & { data: IDataBlock<AmfTransactionDataBlockV1 | AmfSanctionDataBlockV1>, criticity: Criticity, yearOpen: boolean }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [descOpen, setDescOpen] = useState(false);
  const [selected, setSelected] = useState(false);

  const handleDownload = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    window.open(getPDFUrlBodacc(props.data) || '', '_blank');
  };

  const handleOpenLink = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    window.open(getWebsiteUrlBodacc(props.data) || '', '_blank');
  };

  useEffect(() => {
    if (props.selectedId === getIdBodacc(props.data)) {
      setDescOpen(true);
      setSelected(true);
      setTimeout(()=>{
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    } else if (selected) {
      setSelected(false);
    }
  }, [props.selectedId]);

  useEffect(() => {
    if (!descOpen && selected) {
      setSelected(false);
    }
  }, [descOpen]);

  useEffect(() => {
    if (!props.yearOpen) {
      setSelected(false);
      setDescOpen(false);
    }
  }, [props.yearOpen]);

  return (
    <Paper
      ref={ref}
      variant='hoverElevation1'
      onClick={() => setDescOpen(prevState => !prevState)}
      sx={{
        p: 1,
        pl: 2,
        width: 'calc(100% - 32px)',
        border: 'solid 1px',
        borderColor: selected ? theme.primaryColors?.light : 'grey.500',
        bgcolor: selected ? theme.primaryColors?.light : 'grey.50',
        transition: 'background-color 200ms, border-color 200ms',
        '&:hover': {
          cursor: 'pointer',
          borderColor: selected ? theme.primaryColors?.light : 'grey.200',
          bgcolor: selected ? theme.primaryColors?.light :'grey.50',
          transition: 'background-color 200ms, border-color 200ms'
        }
      }}
    >
      <Stack spacing={2} width='100%'>
        <Stack direction='row' spacing={2} alignItems='center' height='28px' p={4} pl={2} pr={1}>
          <Box
            sx={{
              width: '14px',
              height: '14px',
              borderRadius: 2,
              mr: 1,
              backgroundColor: getCriticityBodacc(props.data) ? props.criticity[getCriticityBodacc(props.data) || 'alert'].main : 'grey.500'
            }}
          />
          <Typography>
            {getTooltipBodacc(props.data)}
          </Typography>
          <Stack direction='row' spacing={0} alignItems='center' height='28px' ml='auto !important' pt='1px'>
            <Tooltip title={t('common.utils.openLink')} arrow placement='top'>
              <Button
                variant='icon'
                size='small'
                onClick={handleOpenLink}
                sx={{
                  mr: '-4px',
                  transform: 'scale(0.8)'
                }}
              >
                <IconExternalLink size='1.4rem' />
              </Button>
            </Tooltip>
            <Tooltip title={t('common.utils.downloadFile')} arrow placement='top'>
              <Button
                variant='icon'
                size='small'
                onClick={handleDownload}
                sx={{
                  mr: '-4px',
                  transform: 'scale(0.8)'
                }}
              >
                <IconDownload size='1.4rem' />
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
        {descOpen &&
          <>
            <Divider />
            <Box width='100%' sx={{ overflowX: 'auto' }} p={2}>
              <Stack spacing={2}>
                <Box sx={{ width: 'max-content' }}>
                  <KeyValueList
                    values={[[
                      {
                        key: 'Date',
                        value: getDateBodacc(props.data)
                          ?.toLocaleString() || ''
                      },
                      {
                        key: t('amf.keys.decision'),
                        value: getTextBodacc(props.data)
                          ?.toLocaleString()
                          .replace(/<[^>]*>/g, '') || ''
                      },
                      {
                        key: t('amf.keys.textEdgard'),
                        value: getTextMoreBodacc(props.data)
                          ?.toLocaleString()
                          .replace(/<[^>]+>/g, '')
                          .replace(/(\r?\n|\\r|\\n)/g, ', ')
                          .replace(/,\s*,/g, ', ')
                          .trim()
                      }
                    ]]}
                  />
                </Box>
              </Stack>
            </Box>
          </>
        }
      </Stack>
    </Paper>
  );
}

export function BodaccYearEvents(props: Omit<BodaccElementsProps, 'years' | 'select'> & { yearOpen: boolean }): ReactElement {
  const { t, i18n } = useTranslation();
  const monthNb = Array.from(Array(12).keys());

  return (
    <Stack spacing={5}>
      {monthNb.map(nb => (
        props.datas.filter(data => getDateBodacc(data) && getDateBodacc(data)?.getMonth() === nb).length > 0 &&
        <Stack key={nb.toString()} spacing={2}>
          <Divider sx={{ mb: 3 }} />
          <Typography variant='h5' width='70px' textAlign='center'>
            {t(`common.month.${nb + 1}`)}
          </Typography>
          <Stack spacing={2}>
            {props.datas.filter(data => getDateBodacc(data) && getDateBodacc(data)?.getMonth() === nb).map(data => (
              <Stack key={`${nb}-${data.dataInfo.path === 'company.bodacc.publication' ? (data.data as BodaccDataBlock).id : data.dataInfo.path === 'amf.transaction' ? (data.data as AmfTransactionDataBlockV1).transaction.amfId : (data.data as AmfSanctionDataBlockV1).sanction.amfId}`} direction='row' spacing={3} alignItems='center' pl={5}>
                <Typography variant='subtitle1' alignSelf='flex-start' pt='7px'>
                  {getDateBodacc(data) && `${getDateBodacc(data)?.getDate().toLocaleString(i18n.language, { minimumIntegerDigits: 2 })}`}
                </Typography>
                {data.dataInfo.path === 'company.bodacc.publication'
                  ? <BodaccEvent data={data as IDataBlock<BodaccDataBlock>} criticity={props.criticity} selectedId={props.selectedId} yearOpen={props.yearOpen} />
                  : <BodaccAMFEvent data={data as IDataBlock<AmfTransactionDataBlockV1 | AmfSanctionDataBlockV1>} criticity={props.criticity} selectedId={props.selectedId} yearOpen={props.yearOpen} />
                }
              </Stack>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}

export default BodaccEvent;
