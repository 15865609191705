import React, { ReactElement, useRef, useState } from 'react';
import Grid, { GridBaseProps } from '@mui/material/Grid2';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { IconChevronDown, IconScreenshot } from '@tabler/icons-react';
import Box from '@mui/material/Box';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FullscreenProvider from '@/wrappers/fullscreen';
import TitleComponent, { TitleComponentProps } from '@/components/title';
import captureScreenshot from '@/utils/screenshot';

function DashboardSection(props: Partial<TitleComponentProps> & { direction?: GridBaseProps['direction'], spacing?: number, children?: ReactElement | ReactElement[] }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const ref = useRef(null);
  const [hovered, setHovered] = useState(false);

  const screenCaptureButton = !props.disabled ? (
    <Box pr={1}>
      <Tooltip title={t('common.utils.captureScreenshot')} placement='top' arrow>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            captureScreenshot(ref);
          }}
          disableTouchRipple
          size='small'
          sx={{ color: hovered ? theme.palette.text.secondary : 'transparent' }}
        >
          <IconScreenshot size='1.2rem' />
        </IconButton>
      </Tooltip>
    </Box>
  ) : null;

  return (
    <Accordion
      ref={ref}
      defaultExpanded
      sx={{
        border: 'none',
        backgroundColor: 'transparent',
        p: 0,
        width: '100%'
      }}
    >
      <AccordionSummary
        expandIcon={<IconChevronDown size='1.2rem' />}
        aria-controls={`${props.title}-content`}
        id={`${props.title}-header`}
        sx={{
          p: 0,
          pr: 2,
          pb: 2,
          '&.Mui-expanded': {
            margin: '0 !important'
          },
          '&.MuiAccordionSummary-root': {
            height: '38px !important',
            minHeight: '38px !important'
          }
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {props.title ? <TitleComponent {...props} title={props.title} size='medium' /> : null }
        {screenCaptureButton}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0
        }}
      >
        <FullscreenProvider>
          <Grid container spacing={props.spacing || 2} direction={props.direction} overflow='auto'>
            {Array.isArray(props.children) ? props.children?.map(child => (
              <Grid key={child.key} size='grow' minWidth='min-content'>
                {child}
              </Grid>
            )) :
              <Grid size='grow' minWidth='min-content'>
                {props.children}
              </Grid>
            }
          </Grid>
        </FullscreenProvider>
      </AccordionDetails>
    </Accordion>
  );
}

export default DashboardSection;
